import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ENotificationSeverity, INotification } from '../../../../core/model/notification';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { appAdministrationRouteNames } from '../../../../app.routes';
import { Store } from '@ngrx/store';
import { IState } from '../../../../core/store/state';
import { selectNotifications } from '../../../../core/store/notification/notification.selectors';
import { map } from 'rxjs/operators';

/**
 * Notification banner component.
 */
@Component({
    selector: 'app-notifications-banner',
    templateUrl: './notification-banner.component.html'
})
export class NotificationBannerComponent implements OnInit, OnChanges {

    /**
     * The unit filter.
     */
    @Input() public unitFilter?: string;

    /**
     * Status if notifications with severity error exists.
     */
    public hasErrorNotifications$: Observable<boolean>;

    /**
     * Status if notifications with severity warning exists.
     */
    public hasWarningNotifications$: Observable<boolean>;

    /**
     * Total amount of notifications.
     */
    public allNotificationsCount$: Observable<number>;

    /**
     * Route names use for internal navigation.
     */
    public routeNames = appAdministrationRouteNames;

    /**
     * The internal unit filter observable.
     */
    private unitFilter$ = new BehaviorSubject('');

    /**
     * Constructor of notification banner component.
     */
    constructor(store: Store<IState>) {
        const hasSeverity = (notifications: INotification[], severity: ENotificationSeverity): boolean =>
            notifications.filter((notification) => notification.severity === severity).length >= 1;

        const notifications$ = combineLatest([
            store.select(selectNotifications),
            this.unitFilter$.asObservable()
        ]).pipe(
            map(([notifications, unitFilter]) => notifications.filter((notification) => {
                if (unitFilter === '') {
                    return true;
                }
                return notification.unitSerialNumber === unitFilter;
            }))
        );

        this.hasErrorNotifications$ = notifications$
            .pipe(map((ntfs) => hasSeverity(ntfs, ENotificationSeverity.Error)));
        this.hasWarningNotifications$ = notifications$
            .pipe(map((ntfs) => hasSeverity(ntfs, ENotificationSeverity.Warning)));
        this.allNotificationsCount$ = notifications$.pipe(map((ntfs) => ntfs.length));
    }

    /**
     * Update filter from input.
     */
    public ngOnInit(): void {
        if (this.unitFilter !== undefined) {
            this.unitFilter$.next(this.unitFilter);
        }
    }

    /**
     * Update filter when input value changes.
     */
    public ngOnChanges({ unitFilter }: SimpleChanges): void {
        if (unitFilter !== undefined && unitFilter.currentValue !== undefined) {
            this.unitFilter$.next(unitFilter.currentValue);
        }
    }
}
