import { TOneOrMany } from '../util/generic-types';

/**
 * User roles.
 */
// eslint-disable-next-line no-shadow
export enum EUserRole {
    SuperAdministrator = 'superadmin',
    Administrator = 'admin',
    AreaManager = 'areamanager',
    Technician = 'technician',
}

/**
 * Enum key string union for usage in templates.
 */
export type TUserRoleKeys = TOneOrMany<keyof typeof EUserRole>;

/**
 * Selector type for user roles.
 */
export type TUserRoleSelector = TUserRoleKeys | 'all';

/**
 * The interfaces for users.
 */
export interface IUser {
    /* eslint-disable jsdoc/require-jsdoc */
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    zipCode: string;
    city: string;
    country: string;
    role: EUserRole;
    password?: string;
    groupIds: string[];
    unitIds: string[];
    /* eslint-enable jsdoc/require-jsdoc */
}
