import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../../../../core/service/user/user.service';
import { Environment } from '../../../../../environments/environment-interface';
import { IGroup } from '../../../../core/model/group';
import { ProcessService } from '../../../../core/service/process/process.service';
import { Store } from '@ngrx/store';
import { IState } from '../../../../core/store/state';
import { loadGroups } from '../../../../core/store/data/data.actions';
import { LoggerService } from '../../../../core/service/logger.service';

/**
 * Modal to create a new group.
 */
@Component({
    selector: 'app-modal-add-group',
    templateUrl: './modal-add-group.component.html',
})
export class ModalAddGroupComponent {
    /**
     * Show success message after group was created.
     */
    public groupCreated = false;

    /**
     * The process id to create a group.
     */
    public createGroupProcessId = '@shared/create-group';

    /**
     * The emitter when a group was created.
     */
    @Output() public readonly groupCreate = new EventEmitter<IGroup>();

    /**
     * Constructor of create component.
     */
    constructor(
        private bsModalRef: BsModalRef,
        private userService: UserService,
        private router: Router,
        private environment: Environment,
        private processService: ProcessService,
        private store: Store<IState>,
        private logger: LoggerService
    ) { }

    /**
     * Create a new group.
     */
    public createAndAssign(group: IGroup): void {
        this.processService.startProcess(
            this.userService.createGroup(group),
            this.createGroupProcessId
        )
            .then((groupId) => {
                this.groupCreated = true;
                this.groupCreate.emit({ ...group, id: groupId });
                this.store.dispatch(loadGroups({ force: true }));
            })
            .then(() => new Promise((resolve) => setTimeout(
                resolve,
                this.environment.successRedirectDurationSeconds * 1000
            )))
            .then(() => this.close())
            .catch((error) => {
                this.logger.error('Failed to create and assign group', error, group);
            });
    }

    /**
     * Close the modal.
     */
    public close(): void {
        this.bsModalRef.hide();
    }
}
