import { IEnvironmentInterface } from './environment-interface';

/**
 * The default environment.
 */
export const environmentDefault: IEnvironmentInterface = {
    production: true,
    countryFavorites: ['DE', 'GB'],
    passwordMinLength: 6,
    successRedirectDurationSeconds: 2,
    searchDebounceDelayMs: 100,
    apiBasePath: ''
};
