import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EChartType, IChartSettings } from '../../../../core/model/chart-settings';
import { IChartSettingsChange } from '../../../../core/model/chart-settings-change';

/**
 * A dynamic chart component.
 */
@Component({
    selector: 'app-chart-dynamic',
    templateUrl: './chart-dynamic.component.html',
})
export class ChartDynamicComponent {
    /**
     * The chart settings.
     */
    @Input() public settings?: IChartSettings;

    /**
     * Event emitter for removing the chart.
     */
    @Output() public chartRemove = new EventEmitter<void>();

    /**
     * Event emitter for a settings change.
     */
    @Output() public settingsChange = new EventEmitter<IChartSettingsChange>();

    /**
     * Public accessor for chart type enum in template.
     */
    public ChartType = EChartType;

    /**
     * Bypass the chart remove event to parent.
     */
    public onChartRemove(): void {
        this.chartRemove.emit();
    }

    /**
     * Bypass the setting change event to parent.
     */
    public onSettingChange(settingsChange: IChartSettingsChange) {
        this.settingsChange.emit(settingsChange);
    }
}
