import { Injectable } from '@angular/core';

/**
 * Layout settings interface.
 */
export interface ILayoutSettings {
    /* eslint-disable jsdoc/require-jsdoc */
    offsidebarOpen: boolean;
    isCollapsed: boolean;
    isCollapsedText: boolean;
    asideHover: boolean;
    isFixed: boolean;
    asideToggled: boolean;
    isFloat: boolean;
    hiddenFooter: boolean;
    horizontal: boolean;
    useFullLayout: boolean;
    isBoxed: boolean;
    /* eslint-enable jsdoc/require-jsdoc */
}

/**
 * The layout service.
 */
@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    /**
     * The layout settings.
     */
    private settings: ILayoutSettings = {
        offsidebarOpen: false,
        isCollapsed: false,
        isCollapsedText: false,
        asideHover: false,
        isFixed: true,
        asideToggled: false,
        isFloat: false,
        hiddenFooter: false,
        horizontal: false,
        useFullLayout: false,
        isBoxed: false,
    };

    /**
     * Get layout setting. Return all settings if is not specified.
     *
     * @param name The name of the setting.
     */
    public getLayoutSetting(name?: keyof ILayoutSettings): boolean | ILayoutSettings {
        return name ? this.settings[name] : this.settings;
    }

    /**
     * Set layout settings
     *
     * @param name The name of the setting to change.
     * @param value The value.
     */
    public setLayoutSetting(name: keyof ILayoutSettings, value: boolean): void {
        if (typeof this.settings[name] !== 'undefined') {
            this.settings[name] = value;
        }
    }

    /**
     * Toggle a layout settings.
     *
     * @param name The name of the setting.
     */
    public toggleLayoutSetting(name: keyof ILayoutSettings) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }
}
