import { Component } from '@angular/core';
import { SubscriptionComponent } from '../../../../core/component/subscription.component';
import { filter } from 'rxjs/operators';
import { NotificationService } from '../../../../core/service/notification/notification.service';
import { INotification } from '../../../../core/model/notification';

/**
 * Maximum amount of flashing notifications.
 */
const MAX_FLASHING_NOTIFICATIONS = 2;

/**
 * Automatically close a flashing notification after x milliseconds.
 */
const AUTO_REMOVE_FLASHING_NOTIFICATION_DELAY = 5000;

/**
 * Notification alert flash component.
 */
@Component({
    selector: 'app-notification-alert-flash',
    templateUrl: './alert-flash.component.html'
})
export class AlertFlashComponent extends SubscriptionComponent {

    /**
     * Flashing notifications.
     */
    public flashingNotifications: INotification[] = [];

    /**
     * Constructor of notification alert flash component.
     */
    constructor(
        private notificationService: NotificationService
    ) {
        super();
        let creationDateLimit = new Date();
        this.subscriptions.push(
            notificationService.getNotifications(1)
                .pipe(
                    filter(([notification]) =>
                        notification !== undefined && notification.creationDate >= creationDateLimit
                    )
                )
                .subscribe(([notification]) => {
                    this.flashingNotifications = [
                        notification,
                        ...this.flashingNotifications.slice(0, MAX_FLASHING_NOTIFICATIONS - 1)
                    ];
                    creationDateLimit = new Date();
                    this.removeFlashingNotificationWithDelay(notification.id);
                })
        );
    }

    /**
     * Remove a notification.
     */
    public removeNotification(notification: INotification): void {
        this.removeFlashingNotification(notification.id);
    }

    /**
     * Show notification details.
     */
    public showNotificationDetails(notification: INotification) {
        this.notificationService.showNotificationDetails(notification);
        this.removeFlashingNotification(notification.id);
    }

    /**
     * Remove a flashing notification.
     */
    private removeFlashingNotification(notificationId: string) {
        this.flashingNotifications = this.flashingNotifications
            .filter((notification) => notification.id !== notificationId);
    }

    /**
     * Remove a flashing notification after a static delay.
     */
    private removeFlashingNotificationWithDelay(notificationId) {
        setTimeout(() => this.removeFlashingNotification(notificationId), AUTO_REMOVE_FLASHING_NOTIFICATION_DELAY);
    }
}
