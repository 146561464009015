import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { hasValidator } from '../../../../core/util/form';

/**
 * The delay in milliseconds before auto focusing input elements.
 */
const AUTOFOCUS_DELAY_MS = 100;

/**
 * The default max length.
 */
const DEFAULT_MAX_LENGTH = 255;

/**
 * Possible form input appearance styles.
 */
export type TFormInputAppearance = 'standard' | 'fill' | 'outline';

/**
 * The form input component.
 */
@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
})
export class FormInputComponent implements AfterViewInit, OnInit {
    /**
     * The form control instance.
     */
    @Input() public control: FormControl = new FormControl();

    /**
     * The name of the form field.
     */
    @Input() public name = '';

    /**
     * The placeholder.
     */
    @Input() public placeholder = '';

    /**
     * The name of the form field.
     */
    @Input() public type: 'text' | 'password' = 'text';

    /**
     * Whether the input field should be focused on initialization.
     */
    @Input() private autoFocus = false;

    /**
     * The appearance of the form field.
     */
    @Input() public appearance: TFormInputAppearance = 'standard';

    /**
     * The max length of the input field.
     */
    @Input() public maxLength: number = DEFAULT_MAX_LENGTH;

    /**
     * The text alignment. If undefined align text left.
     */
    @Input() public textAlignment?: 'right' | 'center';

    /**
     * The suffix label of the input.
     */
    @Input() public suffix?: string;

    /**
     * The id of the form field. If the id is not defined
     * the material framework will automatically generate a
     * dynamic id (mat-input-10).
     */
    @Input() public id = '';

    /**
     * Use input field as a number only field. Means the input field
     * has behaviours of a number field (type="number") but is still
     * a text input. There are no guarantees that the input are numbers
     * only. Depending on device and browser a char input is possible.
     */
    @Input() public onlyNumbers = false;

    /**
     * Show a clear button if at least one character was entered.
     */
    @Input() public clearable = false;

    /**
     * The reference to the input form field.
     */
    @ViewChild('inputFormField') inputFormField?: ElementRef;

    /**
     * Is the form input mandatory.
     */
    public mandatory = false;

    /**
     * Angular lifecycle hook after view is initialized.
     */
    public ngAfterViewInit() {
        if (this.autoFocus) {
            // Delay is necessary for proper work.
            setTimeout(() => {
                this.inputFormField?.nativeElement.focus();
            }, AUTOFOCUS_DELAY_MS);
        }
    }

    /**
     * On component initialized.
     */
    ngOnInit(): void {
        // Set form input as mandatory if a required validator exists.
        if (hasValidator(this.control, 'required')) {
            this.mandatory = true;
        }
    }
}
