/* eslint-disable jsdoc/require-jsdoc */

import { IDataQuery } from './data-query';

/**
 * All available chart types.
 */
// eslint-disable-next-line no-shadow
export enum EChartType {
    SimpleLine
}

/**
 * The interface for chart settings.
 */
export interface IChartSettings {
    type: EChartType;
    title: string;
    height: number;
    virtualWidth: number;
    editable: boolean;
    editModeActive: boolean;
    dataQuery: IDataQuery;
}
