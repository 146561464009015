/* eslint-disable jsdoc/require-jsdoc */

/**
 * Static core process ids.
 */
// eslint-disable-next-line no-shadow
export enum EProcessId {
    CoreDataLoadingUnits = '@core/data/loading-units',
    CoreDataLoadingDownSamplingTypes = '@core/data/loading-down-sampling-types',
    CoreDataLoadingSensorFields = '@core/data/loading-sensor-fields',
    CoreDataLoadingGroups = '@core/data/loading-groups',
    CoreNotificationConfirm = '@core/notification/confirm'
}

/**
 * The process interface.
 */
export interface IProcess {
    id: string | EProcessId;
    lockUi: boolean;
}

/**
 * The Process id type.
 */
export type TProcessId = string;
