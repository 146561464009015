import { Component } from '@angular/core';
import { appRouteNames } from '../../../app.routes';

/**
 * The not authorized component.
 */
@Component({
    selector: 'app-not-authorized',
    templateUrl: './not-authorized.component.html'
})
export class NotAuthorizedComponent {

    /**
     * Accessor for route names in template.
     */
    public appRoutes = appRouteNames;
}
