import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { hasValidator } from '../../../../core/util/form';
import { TFormInputAppearance } from '../input/form-input.component';
import { BehaviorSubject, combineLatest, merge, Observable, of, startWith } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TOneOrMany } from '../../../../core/util/generic-types';
import { SubscriptionComponent } from '../../../../core/component/subscription.component';

/**
 * The type for multi select options.
 *
 * Scheme:
 * [
 *  [[<value>, '<label>']]
 * ]
 */
export type TFormMultiSelectOptions = Array<[string | number, string]>;

/**
 * Type for option labels map
 */
type TOptionLabelMap = ReadonlyMap<string | number, string>

/**
 * The multi select form component.
 */
@Component({
    selector: 'app-form-multi-select',
    templateUrl: './form-multi-select.component.html',
})
export class FormMultiSelectComponent extends SubscriptionComponent implements OnInit, OnChanges {
    /**
     * The form control instance.
     */
    @Input() public control: FormControl = new FormControl<TOneOrMany<number> | TOneOrMany<string>>('');

    /**
     * The name of the form field.
     */
    @Input() public name = '';

    /**
     * The options.
     */
    @Input() public options?: TFormMultiSelectOptions = [];

    /**
     * The appearance of the form field.
     */
    @Input() public appearance: TFormInputAppearance = 'standard';

    /**
     * Enable search for options.
     */
    @Input() public enableSearch = false;

    /**
     * Allow multiple option selection.
     */
    @Input() public multipleOptions = false;

    /**
     * The observable to unset filters.
     */
    @Input() public unsetOption = new Observable();

    /**
     * Is the form input mandatory.
     */
    public mandatory = false;

    /**
     * The search input control.
     */
    public searchFormControl = new FormControl<string>('', { nonNullable: true });

    /**
     * The options as observable matching the search.
     */
    public optionSearchResult$: Observable<TFormMultiSelectOptions>;

    /**
     * All option labels as map.
     */
    private optionLabels$ = new BehaviorSubject<TOptionLabelMap>(<TOptionLabelMap>{});

    /**
     * The options as observable.
     */
    private options$ = new BehaviorSubject<TFormMultiSelectOptions>([]);

    /**
     * The selection option title.
     */
    public selectedOptionTitle = '';

    /**
     * The selected option title shortened.
     */
    public selectedOptionTitleShort = '';

    /**
     * Constructor of form multi select component.
     */
    constructor() {
        super();
        // Create an observable with all options.
        this.optionSearchResult$ = combineLatest([
            merge(
                of(''),
                this.searchFormControl.valueChanges.pipe(
                    debounceTime(500),
                    map((searchTerm) => searchTerm.toLowerCase()),
                    distinctUntilChanged()
                ),
            ),
            this.options$
        ]).pipe(
            map(([searchTerm, options]) => {
                if (searchTerm === '') {
                    return options;
                }
                return options.filter(([, value]) => value.toLowerCase().includes(searchTerm));
            })
        );
    }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        // Set form input as mandatory if a required validator exists.
        if (hasValidator(this.control, 'required')) {
            this.mandatory = true;
        }

        if (this.options !== undefined && this.options.length !== 0) {
            this.updateOptions(this.options);
        }

        // Unset options triggered from outside the component.
        this.unsetOption.subscribe((optionToUnset) => {
            const { value } = this.control;
            if (['string', 'number'].includes(typeof value)) {
                if (value === optionToUnset) {
                    this.control.patchValue('');
                }
            } else {
                this.control.patchValue(value.filter((currentOption) => currentOption !== optionToUnset));
            }
        });

        this.subscriptions.push(
            combineLatest([
                this.optionLabels$.asObservable(),
                this.control.valueChanges.pipe(startWith(this.control.value))
            ]).subscribe(([optionLabels, selectedValues]) => {
                if (Array.isArray(selectedValues)) {
                    const labels = selectedValues.map((value) => this.getOptionLabel(optionLabels, value));
                    this.selectedOptionTitle = labels.join(', ');
                    this.selectedOptionTitleShort = this.selectedOptionTitle;
                    if (selectedValues.length > 1) {
                        const titleAddition = selectedValues.length === 2
                            ? '+1 other' : `+${selectedValues.length - 1} others`;
                        this.selectedOptionTitleShort = `${labels[0]} (${titleAddition})`;
                    }
                } else {
                    this.selectedOptionTitle = this.getOptionLabel(optionLabels, selectedValues);
                    this.selectedOptionTitleShort = this.selectedOptionTitle;
                }
            })
        );
    }

    /**
     * On changes lifecycle hook.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.options !== undefined && changes.options.currentValue !== undefined) {
            this.updateOptions(changes.options.currentValue);
        }
    }

    /**
     * Track option by its value.
     */
    public trackByOptionValue(index: number, option: [string | number, string]) {
        return option[0];
    }

    /**
     * Apply new options to the observable and create a map of labels.
     */
    private updateOptions(options: TFormMultiSelectOptions): void {
        this.options$.next(options);
        this.optionLabels$.next(
            <TOptionLabelMap>options.reduce((labels, [value, label]) => {
                labels[value] = label;
                return labels;
            }, { })
        );
    }

    /**
     * Get option label of value.
     */
    private getOptionLabel(openLabelMap: TOptionLabelMap, value: string | number): string {
        return openLabelMap[value] ?? '';
    }
}
