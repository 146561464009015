import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { buffer, concatMap, from, Observable, Subject, tap, throwError } from 'rxjs';
import { catchError, debounceTime, map, take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap';
import { ModalErrorComponent } from '../../shared/component/modal/error/modal-error.component';
import { TranslateService } from '@ngx-translate/core';

/**
 * Handle errors only in urls which includes this pattern.
 */
const HANDLE_ERROR_URL_PATTERN = '/api/';

/**
 * Interceptor for HttpClient to notify the user if a response
 * was returned with an error code.
 */
@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {

    /**
     * All error responses as observable.
     */
    private errorResponses$ = new Subject<HttpResponse<unknown>>();

    /**
     * Constructor of the http error handler interceptor.
     */
    constructor(private translateService: TranslateService, modalService: BsModalService) {
        this.errorResponses$.asObservable().pipe(
            // Buffer error responses to avoid too many notifications to user.
            buffer(this.errorResponses$.asObservable().pipe(debounceTime(1000))),
            // Deduplicate buffer entries.
            map((eventBuffer) => [
                ...(new Map(eventBuffer.map((event) => [`${event.status}${event.url}`, event]))).values()
            ]),
            // Group responses by HTTP status codes.
            map((eventEntries) =>
                <Array<[string, HttpResponse<unknown>[]]>>Object.entries(eventEntries.reduce((eventGroups, event) => {
                    const { status } = event;
                    eventGroups[status] = eventGroups[status] === undefined
                        ? [event] : [...eventGroups[status], event];
                    return eventGroups;
                }, { }))),
            concatMap((eventEntryGroups) => from(eventEntryGroups)),
            concatMap(([statusCode, events]) => {
                const modalRef = modalService.show(
                    ModalErrorComponent,
                    {
                        initialState: {
                            title: this.renderErrorTitle(statusCode),
                            message: this.renderErrorMessage(statusCode),
                            additionalMessage: this.renderAdditionalMessage(events),
                        }
                    }
                );
                return modalRef.content.dismiss.pipe(take(1));
            })
        ).subscribe();
    }

    /**
     * Intercept the http event stream.
     */
    public intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            tap((httpEvent) => {
                this.handleHttpEvent(httpEvent);
            }),
            catchError((event) => {
                this.handleHttpEvent(event);
                return throwError(event);
            })
        );
    }

    /**
     * Filter stream of http events by status code and url pattern
     * and forward them to error queue.
     */
    private handleHttpEvent(httpEvent: HttpEvent<unknown>): void {
        if (
            (httpEvent.type === HttpEventType.Response || httpEvent.type === undefined)
            && httpEvent.status >= 400
            && typeof httpEvent.url === 'string'
            && httpEvent.url.includes(HANDLE_ERROR_URL_PATTERN)
        ) {
            this.errorResponses$.next(httpEvent);
        }
    }

    /**
     * Render the error title.
     */
    private renderErrorTitle(statusCode: string): string {
        const translationKey = `errors.httpResponse.statusCode.${statusCode}.title`;
        const translation = this.translateService.instant(translationKey);
        if (translation === translationKey) {
            return this.translateService.instant('errors.httpResponse.statusCode.default.title');
        }
        return translation;
    }

    /**
     * Render error message.
     */
    private renderErrorMessage(statusCode: string) {
        let translation: string;
        const translationKey = `errors.httpResponse.statusCode.${statusCode}.message`;
        translation = this.translateService.instant(translationKey);
        if (translation === translationKey) {
            translation = this.translateService.instant('errors.httpResponse.statusCode.default.message');
        }
        return translation;
    }

    /**
     * Render additional error message.
     */
    private renderAdditionalMessage(errorResponses: HttpResponse<unknown>[]) {
        return errorResponses.map((resp : any) => {
            if (resp.error && resp.error.errors) {
                return resp.error.errors.join('<br />');
            } else if (resp.error) {
                return resp.error;
            }

            return `${resp.statusText}`;

        }).join('<br />');
    }
}
