import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { IDataQuery, IDataQueryRequest } from '../../model/data-query';
import { IChartDataSeries, TChartData } from '../../model/chart-data';
import { adjustDateMinuteCeil } from '../../util/time-range';
import { Store } from '@ngrx/store';
import { IState } from '../../store/state';
import { selectUnits } from '../../store/data/data.selectors';

/**
 * The chart service to load chart data from API.
 */
@Injectable()
export class ChartService {

    /**
     * Map of all unit names using serial number as key.
     */
    private unitNameMap = new Map<string, string>();

    /**
     * Constructor of the data service.
     */
    constructor(private dataService: DataService, store: Store<IState>) {
        store.select(selectUnits).subscribe((units) => {
            units.forEach(({ serialNumber, displayName }) => {
                this.unitNameMap.set(serialNumber, displayName);
            });
        });
    }

    /**
     * Load chart data from query.
     */
    public loadData(query: IDataQuery): Promise<TChartData> {
        const queryRequests = query.sensorFields.reduce<Array<Promise<IDataQueryRequest>>>(
            (queryResult, sensorField) => {
                const [from, to] = query.timeRange;
                const toAdjusted = adjustDateMinuteCeil(to);
                if (sensorField.downSamplingTypes.length === 0) {
                    queryResult.push(
                        this.dataService.getSensorsValues(query.units, [sensorField.name], from, toAdjusted)
                            .then((response) => ({
                                response: response.reverse(),
                                sensorField: sensorField.name,
                                downSamplingType: ''
                            }))
                    );
                } else {
                    sensorField.downSamplingTypes.forEach((downSamplingType) => {
                        queryResult.push(
                            this.dataService.getSensorsValues(
                                query.units,
                                [sensorField.name],
                                from,
                                to,
                                downSamplingType
                            )
                                .then((response) => ({
                                    response: response.reverse(),
                                    downSamplingType,
                                    sensorField: sensorField.name,
                                }))
                        );
                    });
                }
                return queryResult;
            },
            []
        );

        return Promise.all(queryRequests).then((requests) => requests.reduce<TChartData>((chartData, request) => [
            ...chartData,
            ...this.extractSeriesfromRequest(request)
        ], <TChartData>[]));
    }

    /**
     * Extract series from response.
     */
    private extractSeriesfromRequest(request: IDataQueryRequest): IChartDataSeries[] {
        const seriesMap = request.response.reduce((series, responseValue) => {
            // eslint-disable-next-line object-curly-newline
            const { ups, field, value, timestamp } = responseValue;
            const seriesId = `${ups}${field}${request.downSamplingType}`;
            let seriesData = series[seriesId];
            if (seriesData === undefined) {
                seriesData = {
                    name: this.getSeriesName(<string>ups, <string>field, request.downSamplingType),
                    data: [],
                    type: 'line',
                };
            }

            const time = new Date(<string>timestamp);
            // Transform the unix timestamp to a "regional" timestamp.
            const regionalTimeStamp = time.getTime() - time.getTimezoneOffset() * 60000;
            seriesData.data.push([regionalTimeStamp, value]);
            series[seriesId] = seriesData;
            return series;
        }, <Record<string, IChartDataSeries>>{});

        return Object.values(seriesMap);
    }

    /**
     * Get series name.
     */
    private getSeriesName(unitSerialNumber: string, field: string, downSamplingType: string): string {
        return [
            this.unitNameMap.has(unitSerialNumber) ? this.unitNameMap.get(unitSerialNumber) : unitSerialNumber,
            field,
            downSamplingType
        ].filter(Boolean).join(' / ');
    }
}
