import { EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsNoDataToDisplayModule from 'highcharts/modules/no-data-to-display';
import HighchartsExportingModule from 'highcharts/modules/exporting';
import HighchartsExportingOfflineModule from 'highcharts/modules/offline-exporting';
import HighchartsExportDataModule from 'highcharts/modules/export-data';
import { v4 as uid } from 'uuid';
import { Chart, SeriesLineOptions } from 'highcharts';
import { IDataQuery } from '../model/data-query';
import { createNewTimeRange } from '../util/time-range';
import { FormControl, FormControlStatus } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { ChartService } from '../service/chart/chart.service';
import { LoggerService } from '../service/logger.service';
import { EChartType, IChartSettings } from '../model/chart-settings';
import { ModalConfirmComponent } from '../../shared/component/modal/confirm/modal-confirm.component';
import { ProcessService } from '../service/process/process.service';
import { ModalChartDataTableComponent } from '../../shared/component/modal/chart-data-table/chart-data-table.component';
import { IChartSettingsChange, TChartSettingsModifiedKeys } from '../model/chart-settings-change';
HighchartsNoDataToDisplayModule(Highcharts);
HighchartsExportingModule(Highcharts);
HighchartsExportingOfflineModule(Highcharts);
HighchartsExportDataModule(Highcharts);

/**
 * Helper function to create chart options.
 */
export function createChartOptions(
    showDataClickHandler?: () => void,
    overrideOptions?: Highcharts.Options
): Highcharts.Options {
    return {
        title: { text: '' },
        lang: { noData: 'No data available' },
        series: [],
        chart: {
            height: 400,
            reflow: true
        },
        exporting: {
            menuItemDefinitions: {
                // Custom definition
                modalViewData: {
                    onclick: showDataClickHandler,
                    text: 'Show data table'
                }
            },
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen', 'separator',
                        'downloadPNG', 'downloadSVG', 'downloadCSV',
                        'modalViewData'
                    ]
                }
            }
        },
        ...(overrideOptions ?? { })
    };
}

/**
 * The default chart settings.
 */
export const DEFAULT_CHART_SETTINGS: IChartSettings = {
    type: EChartType.SimpleLine,
    title: 'New chart',
    height: 400,
    virtualWidth: 12,
    editable: true,
    editModeActive: false,
    dataQuery: {
        units: [],
        sensorFields: [],
        timeRange: createNewTimeRange()
    }
};

/**
 * The abstract chart component class using the Highcharts library.
 */
export abstract class ChartComponent implements OnChanges {
    /**
     * The chart settings.
     */
    @Input() public settings?: Partial<IChartSettings> = {};

    /**
     * Output setting changes.
     */
    @Output() public settingsChange = new EventEmitter<IChartSettingsChange>();

    /**
     * Remove the chart event emitter.
     */
    @Output() public chartRemove = new EventEmitter<void>();

    /**
     * Provide the high charts library to template.
     */
    public Highcharts: typeof Highcharts = Highcharts;

    /**
     * The form control of the chart title.
     */
    public titleFormControl = new FormControl('', { nonNullable: true });

    /**
     * Highcharts configuration for simple line chart.
     */
    public chartOptions: Highcharts.Options = createChartOptions(this.showDataTable.bind(this));

    /**
     * Trigger the update in a chart.
     */
    public updateChart = false;

    /**
     * Status if the query form is valid or not.
     */
    public queryFormValid = true;

    /**
     * The process id for loading chart data.
     */
    public chartLoadDataProcessId = `@core/chart-load-data-${uid()}`;

    /**
     * The internal applied settings.
     */
    public appliedSettings: IChartSettings;

    /**
     * The internal applied settings.
     */
    protected defaultChartSettings: IChartSettings = DEFAULT_CHART_SETTINGS;

    /**
     * The chart instance.
     */
    private chartInstance?: Chart;

    /**
     * Constructor of the chart component.
     */
    constructor(
        private modalService: BsModalService,
        private chartService: ChartService,
        private logger: LoggerService,
        private processService: ProcessService,
    ) {
        this.appliedSettings = {
            ...this.defaultChartSettings,
            ...this.settings
        };
        this.applySettings();
    }

    /**
     * On changes.
     */
    public ngOnChanges({ settings: settingChanges }: SimpleChanges): void {
        if (settingChanges !== undefined && settingChanges.currentValue !== undefined) {
            this.applySettings(settingChanges.currentValue, !settingChanges.firstChange);
        }
    }

    /**
     * Callback when the chart is initialized.
     */
    public onChartInitialized(chart: Chart) {
        this.chartInstance = chart;
        setTimeout(() => {
            if (chart.options !== undefined) {
                chart.reflow();
            }
        }, 100);
    }

    /**
     * Remove the chart.
     */
    public remove(): void {
        this.chartRemove.emit();
    }

    /**
     * Update the title.
     */
    public updateTitle(): void {
        this.applySettings({ title: this.titleFormControl.value });
    }

    /**
     * Reset the title input.
     */
    public resetTitleInput(): void {
        this.titleFormControl.patchValue(this.appliedSettings.title);
    }

    /**
     * Request disabling editing mode.
     */
    public requestDisableEditingMode(): void {
        if (!this.queryFormValid) {
            const confirmRejectChangesModal = this.modalService.show(ModalConfirmComponent, {
                class: 'modal-sm',
                initialState: {
                    title: 'Discard changes',
                    message: 'Some of the edited filters are invalid. If you continue, '
                        + 'the changes will be rejected. Are you sure you want to continue?',
                    acceptLabel: 'Continue'
                }
            });
            confirmRejectChangesModal.content.result.subscribe((confirmed) => {
                if (confirmed) {
                    this.disableEditingMode();
                }
            });
        } else {
            this.disableEditingMode();
        }
    }

    /**
     * Enable editing mode.
     */
    public enableEditingMode(): void {
        this.applySettings({ editModeActive: true });
    }

    /**
     * Disable editing mode.
     */
    private disableEditingMode(): void {
        this.applySettings({ editModeActive: false });
        this.loadData();
    }

    /**
     * Event handler when query was modified by user inside the chart.
     */
    public onQueryModified(dataQuery: IDataQuery) {
        this.applySettings({ dataQuery });
    }

    /**
     * Set the query form valid status.
     */
    public setQueryFormStatus(status: FormControlStatus) {
        this.queryFormValid = status === 'VALID';
    }

    /**
     * Show data table of current chart data.
     */
    public showDataTable(): void {
        if (this.chartInstance !== undefined) {
            const dataRows = this.chartInstance.getDataRows();
            this.modalService.show(ModalChartDataTableComponent, {
                class: 'modal-lg',
                initialState: {
                    title: this.appliedSettings.title,
                    header: dataRows[0] ?? [],
                    dataRows: dataRows.splice(1).reverse(),
                }
            });
            this.chartInstance.getDataRows();
        }
    }

    /**
     * Load data.
     */
    private loadData(): void {
        if (this.isDataQueryValid()) {
            this.processService.startProcess(
                this.chartService.loadData(this.appliedSettings.dataQuery),
                this.chartLoadDataProcessId
            )
                .then((chartData) => {
                    this.chartOptions.series = <SeriesLineOptions[]>chartData;
                    this.updateChart = true;
                })
                .catch((error) => {
                    this.logger.error('Failed to load data', this.appliedSettings.dataQuery, error);
                });
        }
    }

    /**
     * Check if the data query is valid or not.
     */
    private isDataQueryValid(): boolean {
        return this.appliedSettings.dataQuery.sensorFields.length >= 1
            && this.appliedSettings.dataQuery.units.length >= 1;
    }

    /**
     * Apply settings and update subcomponents if settings have changed.
     */
    private applySettings(settings?: Partial<IChartSettings>, emitChanges = true): void {
        let settingsChanged = false;
        let loadData = false;
        const modifiedKeys: TChartSettingsModifiedKeys = [];
        const newSettings: IChartSettings = {
            ...this.appliedSettings,
            ...(settings ?? {})
        };

        // Set title form control.
        this.titleFormControl.patchValue(newSettings.title);
        if (this.appliedSettings.title !== newSettings.title) {
            settingsChanged = true;
            modifiedKeys.push('title');
        }

        // Emit changes of edit mode.
        if (this.appliedSettings.editModeActive !== newSettings.editModeActive) {
            settingsChanged = true;
            modifiedKeys.push('editModeActive');
        }

        // Detect changes in query.
        if (JSON.stringify(this.appliedSettings.dataQuery) !== JSON.stringify(newSettings.dataQuery)) {
            settingsChanged = true;
            modifiedKeys.push('dataQuery');
            if (!newSettings.editModeActive) {
                loadData = true;
            }
        }

        // Adjust chart height if modified.
        if (this.appliedSettings.height !== newSettings.height) {
            const chartHeight = newSettings.height - 120;
            this.chartOptions.chart = {
                ...this.chartOptions.chart || {},
                height: chartHeight
            };
            modifiedKeys.push('height');
            this.updateChart = true;
        }

        // Reflow the chart if the virtual width has been changed.
        if (this.appliedSettings.virtualWidth !== newSettings.virtualWidth) {
            this.chartInstance?.reflow();
            modifiedKeys.push('virtualWidth');
        }

        // Emit change event if settings have been modified.
        if (settingsChanged && emitChanges) {
            this.settingsChange.emit({ settings: newSettings, modifiedKeys });
        }
        this.appliedSettings = newSettings;
        if (loadData) {
            this.loadData();
        }
    }
}
