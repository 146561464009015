/**
 * VerificationServer.Service, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';


export interface CreateUserRequest { 
    firstName: string;
    lastName: string;
    email: string;
    street?: string | null;
    zipCode?: string | null;
    city?: string | null;
    country?: string | null;
    role: Role;
    groupIds?: Array<string> | null;
    unitIds?: Array<string> | null;
    password?: string | null;
}

