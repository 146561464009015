/* eslint-disable max-len */
import { createAction, props } from '@ngrx/store';
import { INotification } from '../../model/notification';

// Actions
export const load = createAction('@core/notification/loadUnconfirmed');
export const confirm = createAction('@core/notification/confirm', props<{ notificationId: string }>());
export const remove = createAction('@core/notification/remove', props<{ notificationId: string }>());

// Events
export const loaded = createAction('@core/notification/loaded', props<{ notifications: INotification[] }>());
export const loadingFailed = createAction('@core/notification/loading-failed', props<{ error?: any }>());
export const confirmed = createAction('@core/notification/confirmed');
export const confirmationFailed = createAction('@core/notification/confirmation-failed', props<{ notificationId: string, error?: any }>());
