/* eslint-disable jsdoc/require-jsdoc */
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * The placeholder for a date, which can not be rendered.
 */
const INVALID_DATE_PLACEHOLDER = '-';

/**
 * A modification of Angular date pipe which returns a placeholder
 * string if date could not be transformed (instead of an exception).
 */
@Pipe({
    name: 'appDateInfo',
    pure: true,
})
export class DateInfoPipe extends DatePipe implements PipeTransform {

    /**
     * @inheritDoc
     */
    public transform(value: Date|string|number, format?: string, timezone?: string, locale?: string): string |null;
    public transform(value: null|undefined, format?: string, timezone?: string, locale?: string): null;
    public transform(
        value: Date|string|number|null|undefined, format?: string, timezone?: string,
        locale?: string
    ): string|null;
    public transform(
        value: Date|string|number|null|undefined,
        format = 'mediumDate', timezone?: string, locale?: string
    ): string|null {
        try {
            return super.transform(value, format, timezone, locale);
        } catch(error: unknown) {
            return INVALID_DATE_PLACEHOLDER;
        }
    }
}
