import { IProcess } from '../../model/process';

/**
 * The process state interface.
 */
export type TProcessState = Array<IProcess>;

/**
 * The process state.
 */
export const initialProcessState: TProcessState = [];
