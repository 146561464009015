import { OnDestroy, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * The abstract subscription component simplifies the unsubscribe process for
 * subscribed observables. When the component is going to be destroyed, all
 * subscriptions will be unsubscribed.
 *
 * The injectable decorator has no functionality. It prevents the
 * AOT compiler to throw an error.
 */
@Injectable()
export abstract class SubscriptionComponent implements OnDestroy {

    /**
     * List of subscriptions made on initialization.
     */
    protected subscriptions: Subscription[] = [];

    /**
     * On component destroy. Unsubscribe from
     * all subscriptions.
     */
    public ngOnDestroy(): void {
        this.subscriptions.forEach((currentSubs) => currentSubs.unsubscribe());
    }
}
