import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let $: any;

import { MenuService } from '../../../core/service/menu.service';
import { LayoutService } from '../../../core/service/layout.service';
import { InfoService } from 'src/app/modules/api';
import { TMenu } from '../../../core/model/menu';

/**
 * The sidebar component.
 *
 * TODO: Remove jquery code.
 */
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {

    /**
     * List of menu items.
     */
    public menuItems: TMenu;

    /**
     * The sidebar click event.
     */
    public sbclickEvent = 'click.sidebar-toggle';

    /**
     * The jquery document.
     */
    // eslint-disable-next-line no-null/no-null,@typescript-eslint/no-explicit-any
    public $doc: any = null;

    /**
     * The application version from package.json file.
     */
    public appVersion = '';

    /**
     * Constructor of sidebar component.
     */
    constructor(
        private menuService: MenuService,
        private infoService: InfoService,
        public layoutService: LayoutService,
        private router: Router,
    ) {
        this.menuItems = menuService.getMenu();
    }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        this.router.events.subscribe(() => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.layoutService.setLayoutSetting('asideToggled', false);
        });

        this.infoService.apiInfoGet()
            .subscribe({
                next: (response) => { this.appVersion = response.version || '<unknown>'; },
                error: () => { this.appVersion = '<failed to load>'; }
            });

        // Enable sidebar auto close from clicks outside this component.
        this.anyClickClose();
    }

    /**
     * Add event handler to outo close the sidebar if the user clicks outside the component.
     */
    public anyClickClose(): void {
        this.$doc = $(document).on(this.sbclickEvent, (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.layoutService.setLayoutSetting('asideToggled', false);
            }
        });
    }

    /**
     * Remove event handler when component gets destroyed.
     */
    public ngOnDestroy(): void {
        if (this.$doc) {
            this.$doc.off(this.sbclickEvent);
        }
    }

    /**
     * Toggle clicks on the submenu.
     */
    public toggleSubmenuClick(event): void {

        event.preventDefault();

        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {

            const ul = $(event.currentTarget.nextElementSibling);

            // hide other submenus
            const parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                const $el = $(el);
                // if element is not a parent or self ul
                if (el !== parentNav[0] && el !== ul[0]) {
                    this.closeMenu($el);
                }
            });

            // abort if not UL to process
            if (!ul.length) {
                return;
            }

            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            // toggle UL height
            const ulHeight = ul.css('height');
            if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
                this.closeMenu(ul);
            } else {
                // expand menu
                ul.on('transitionend', () => {
                    ul.css('height', 'auto').off('transitionend');
                }).css('height', ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
            }
        }
    }

    /**
     * Close the sub menu.
     */
    public closeMenu(elem): void {
        elem.css('height', elem[0].scrollHeight); // set height
        elem.css('height', 0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    /**
     * Toggle if submenu gets hovered.
     */
    public toggleSubmenuHover(event): void {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            const ul = $(event.currentTarget.nextElementSibling);
            const anchor = $(event.currentTarget);

            if (!ul.length) {
                return; // if not submenu return
            }

            const $aside = $('.aside-container');
            const $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            const $sidebar = $asideInner.children('.sidebar');
            const mar = parseInt($asideInner.css('padding-top'), 10) + parseInt($aside.css('padding-top'), 10);
            const itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            const floatingNav = ul.clone().appendTo($aside);
            const vwHeight = document.body.clientHeight;

            floatingNav.addClass('nav-floating');

            // each item has ~40px height
            // multiply to force space for at least N items
            const safeOffsetValue = (40 * 5);
            const navHeight = floatingNav.outerHeight(true) + 2; // 2px border
            const safeOffset = navHeight < safeOffsetValue ? navHeight : safeOffsetValue;

            const displacement = 25; // displacement in px from bottom

            // if not enough space to show N items, use then calculated 'safeOffset'
            const menuTop = (vwHeight - itemTop > safeOffset) ? itemTop : (vwHeight - safeOffset - displacement);

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .css({
                    position: this.layoutService.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
                    top: menuTop,
                    bottom: (floatingNav.outerHeight(true) + menuTop > vwHeight) ? (`${displacement}px`) : 'auto'
                });

            floatingNav
                .on('mouseleave', () => { floatingNav.remove(); })
                .find('a').on('click', (e) => {
                    e.preventDefault(); // prevents page reload on click
                    // get the exact route path to navigate
                    const routeTo = $(e.target).attr('route');
                    if (routeTo) {
                        self.router.navigate([routeTo]);
                    }
                });

            this.listenForExternalClicks();
        }
    }

    /**
     * Event handler to listen for external clicks.
     */
    public listenForExternalClicks(): void {
        const $doc = $(document).on('click.sidebar', (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    /**
     * Remove the floating navigation.
     */
    public removeFloatingNav(): void {
        $('.nav-floating').remove();
    }

    /**
     * Check if the sidebar is collapsed.
     */
    public isSidebarCollapsed(): boolean {
        return <boolean> this.layoutService.getLayoutSetting('isCollapsed');
    }

    /**
     * Check if the sidebar has collapsed text.
     */
    public isSidebarCollapsedText(): boolean {
        return <boolean> this.layoutService.getLayoutSetting('isCollapsedText');
    }

    /**
     * Check if hovering is enabled.
     */
    public isEnabledHover(): boolean {
        return <boolean> this.layoutService.getLayoutSetting('asideHover');
    }
}
