import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './service/colors.service';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { ModalConfirmComponent } from './component/modal/confirm/modal-confirm.component';
import { FormCountrySelectComponent } from './component/form/country-select/form-country-select.component';
import { FormErrorsComponent } from './component/form/errors/form-errors.component';
import { FormInputComponent } from './component/form/input/form-input.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormMultiSelectComponent } from './component/form/multi-select/form-multi-select.component';
import { FormPasswordComponent } from './component/form/password/form-password.component';
import { LayoutDefaultComponent } from './component/layout/default/layout-default.component';
import { LayoutEmptyComponent } from './component/layout/empty/layout-empty.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { OffsidebarComponent } from './component/offsidebar/offsidebar.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { UserblockComponent } from './component/userblock/userblock.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { UnitFilterComponent } from './component/unit-filter/unit-filter.component';
import { ChartSimpleLineComponent } from './component/chart/simple-line/chart-simple-line.component';
import { EditableModule } from '@ngneat/edit-in-place';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartDynamicComponent } from './component/chart/dynamic/chart-dynamic.component';
import { ChartQueryFormComponent } from './component/chart/query-form/chart-query-form.component';
import { TimeRangeFilterComponent } from './component/time-range-filter/time-range-filter.component';
import { ModalTimeRangeComponent } from './component/modal/time-range/modal-time-range.component';
import { ModalAddGroupComponent } from './component/modal/add-group/modal-add-group.component';
import { GroupFormComponent } from './component/group-form/group-form.component';
import {
    NotificationModalDetailComponent
} from './component/notification/modal-detail/notification-modal-detail.component';
import { NotificationIconComponent } from './component/notification/icon/notification-icon.component';
import { NotificationTitleComponent } from './component/notification/title/notification-title.component';
import { NotificationMessageComponent } from './component/notification/message/notification-message.component';
import { AlertFlashComponent } from './component/notification/alert-flash/alert-flash.component';
import { NotificationAlertComponent } from './component/notification/alert/notification-alert.component';
import { PageLoaderComponent } from './component/page-loader/page-loader.component';
import { ProcessLoaderDirective } from './directives/process-loader/process-loader.directive';
import { ProcessComponent } from './component/process/process.component';
import { ProcessLoaderComponent } from './component/process-loader/process-loader.component';
import { HumanReadableDurationComponent } from './component/human-readable-duration/human-readable-duration.component';
import { DateInfoPipe } from './directives/date/date-info.pipe';
import { MatTreeModule } from '@angular/material/tree';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ModalChartDataTableComponent } from './component/modal/chart-data-table/chart-data-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SeverityBadgeComponent } from './component/severity-badge/severity-badge.component';
import { FilterableViewComponent } from './component/filterable-view/filterable-view.component';
import { NotificationBannerComponent } from './component/notification/banner/notification-banner.component';
import { UserHasRoleDirective } from './directives/user-has-role/user-has-role.directive';
import { LoginComponent } from './component/login/login.component';
import { NotAuthorizedComponent } from './component/not-authorized/not-authorized.component';
import { ModalErrorComponent } from './component/modal/error/modal-error.component';

/**
 * Creating shared modules in your Angular project helps you to organize and streamline your code.
 * You can gather all the commonly used components, pipes, and directives into one module and then
 * import the entire module whenever needed in any part of your application.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        NgxDaterangepickerMd.forRoot(),
        NgxSliderModule,
        EditableModule,
        NgScrollbarModule,
        // Material Modules
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTableModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatStepperModule,
        MatTreeModule,
        NgxMatSelectSearchModule,
        HighchartsChartModule,
        NgxDatatableModule,
        ScrollingModule
    ],
    providers: [
        ColorsService,
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        LayoutDefaultComponent,
        LayoutEmptyComponent,
        HeaderComponent,
        FooterComponent,
        OffsidebarComponent,
        SidebarComponent,
        UserblockComponent,
        ModalConfirmComponent,
        ModalErrorComponent,
        FormCountrySelectComponent,
        FormErrorsComponent,
        FormInputComponent,
        FormMultiSelectComponent,
        FormPasswordComponent,
        UnitFilterComponent,
        ChartSimpleLineComponent,
        ChartDynamicComponent,
        ChartQueryFormComponent,
        TimeRangeFilterComponent,
        ModalTimeRangeComponent,
        ModalAddGroupComponent,
        GroupFormComponent,
        NotificationModalDetailComponent,
        NotificationIconComponent,
        NotificationTitleComponent,
        NotificationMessageComponent,
        AlertFlashComponent,
        NotificationAlertComponent,
        PageLoaderComponent,
        ProcessComponent,
        ProcessLoaderComponent,
        ProcessLoaderDirective,
        HumanReadableDurationComponent,
        ModalChartDataTableComponent,
        DateInfoPipe,
        SeverityBadgeComponent,
        FilterableViewComponent,
        NotificationBannerComponent,
        UserHasRoleDirective,
        LoginComponent,
        NotAuthorizedComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        DatepickerModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        NgxSliderModule,
        EditableModule,
        NgScrollbarModule,
        // Material Modules
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTableModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatStepperModule,
        MatTreeModule,

        LayoutDefaultComponent,
        LayoutEmptyComponent,
        HeaderComponent,
        FooterComponent,
        OffsidebarComponent,
        SidebarComponent,
        UserblockComponent,
        ModalConfirmComponent,
        ModalErrorComponent,
        FormCountrySelectComponent,
        FormErrorsComponent,
        FormInputComponent,
        FormMultiSelectComponent,
        FormPasswordComponent,
        UnitFilterComponent,
        ChartSimpleLineComponent,
        ChartDynamicComponent,
        ChartQueryFormComponent,
        TimeRangeFilterComponent,
        ModalTimeRangeComponent,
        ModalAddGroupComponent,
        GroupFormComponent,
        NotificationModalDetailComponent,
        NotificationIconComponent,
        NotificationMessageComponent,
        NotificationTitleComponent,
        ProcessComponent,
        ProcessLoaderComponent,
        ProcessLoaderDirective,
        HumanReadableDurationComponent,
        ModalChartDataTableComponent,
        DateInfoPipe,
        SeverityBadgeComponent,
        FilterableViewComponent,
        NotificationAlertComponent,
        NotificationBannerComponent,
        UserHasRoleDirective,
        LoginComponent,
        NotAuthorizedComponent,
    ]
})
export class SharedModule {
    /**
     * The for root implementation.
     */
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}
