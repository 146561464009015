import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TFormMultiSelectOptions } from '../form/multi-select/form-multi-select.component';
import { FormControl } from '@angular/forms';
import { combineLatest, startWith } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IState } from '../../../core/store/state';
import { loadUnits } from '../../../core/store/data/data.actions';
import { selectUnits } from '../../../core/store/data/data.selectors';
import { map } from 'rxjs/operators';
import { SubscriptionComponent } from '../../../core/component/subscription.component';
import { IUnit } from '../../../core/model/unit';
import { TOneOrMany } from '../../../core/util/generic-types';

/**
 * The unit filter component, which provides a multi select input to filter units.
 */
@Component({
    selector: 'app-unit-filter',
    templateUrl: './unit-filter.component.html',
})
export class UnitFilterComponent extends SubscriptionComponent implements OnInit {
    /**
     * The form control for the filter.
     */
    @Input() public unitFilterControl = new FormControl<TOneOrMany<string>>([], { nonNullable: true });

    /**
     * Units selected.
     */
    @Input() private unitsSelected?: TOneOrMany<string>;

    /**
     * Show a list with all active filters.
     */
    @Input() public showActiveFilters = true;

    /**
     * Enable multi selection.
     */
    @Input() public multipleUnits = true;

    /**
     * Add an option to select all units.
     */
    @Input() private addAllOptions = false;

    /**
     * Event emitter when filter has changed.
     */
    @Output() private unitFilterChange = new EventEmitter<TOneOrMany<string>>();

    /**
     * The subject to pass unset filters to multi select box.
     */
    public unsetFilter$ = new Subject<string>();

    /**
     * Observable of unit options.
     */
    private unitOptions$: Observable<TFormMultiSelectOptions>;

    /**
     * List of selected units.
     */
    private selectedUnits: IUnit[] = [];

    /**
     * Constructor of the unit filter component.
     */
    constructor(private store: Store<IState>) {
        super();

        // Create observable of unit options.
        this.store.dispatch(loadUnits({ force: false }));
        this.unitOptions$ = this.store.select(selectUnits).pipe(
            map((units) => {
                const firstOptions = this.addAllOptions ? <TFormMultiSelectOptions>[['', 'All']] : [];
                return firstOptions.concat(units.map((unit) => [unit.serialNumber, unit.displayName]));
            })
        );
    }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        // Create a list of all selected units.
        this.subscriptions.push(combineLatest([
            this.store.select(selectUnits),
            this.unitFilterControl.valueChanges.pipe(startWith(this.unitFilterControl.value)),
        ]).pipe(
            map(([units, selectedUnits]) => {
                if (typeof selectedUnits === 'string') {
                    return units.filter(({ serialNumber }) => serialNumber === selectedUnits);
                }
                return units.filter(({ serialNumber }) => selectedUnits.includes(serialNumber));
            })
        ).subscribe((allSelectedUnits) => {
            this.selectedUnits = allSelectedUnits;
        }));

        // Bypass value changes to output emitter.
        this.subscriptions.push(this.unitFilterControl.valueChanges.subscribe((value) => {
            this.unitFilterChange.emit(value);
        }));

        // Set selected units from input parameter.
        if (this.unitsSelected !== undefined) {
            this.unitFilterControl.patchValue(this.unitsSelected);
        }
    }

    /**
     * Remove a filter from the multi select box.
     */
    public unsetFilter(filterId: string) {
        this.unsetFilter$.next(filterId);
    }
}
