/**
 * The environment interface.
 */
export interface IEnvironmentInterface {
    /**
     * Whether the app is running in production mode or not.
     */
    production: boolean;
    /**
     * Country favorites for country select input.
     */
    countryFavorites: string[];
    /**
     * Minimum length of the password.
     */
    passwordMinLength: number;
    /**
     * The duration in seconds before the user gets redirected
     * after a successful operation.
     */
    successRedirectDurationSeconds: number;
    /**
     * To debounce delay in milliseconds before a search will be processed.
     */
    searchDebounceDelayMs: number;

    /**
     * To debounce delay in milliseconds before a search will be processed.
     */
    apiBasePath: string;
}

/**
 * The environment class.
 */
export class Environment {
    production = true;
    countryFavorites = <string[]>[];
    passwordMinLength = 6;
    successRedirectDurationSeconds = 2;
    searchDebounceDelayMs = 300;
    apiBasePath = '';
}
