import {
    Component, EventEmitter, Inject, Input,
    LOCALE_ID, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { ChangeContext, LabelType, Options as SliderOptions } from '@angular-slider/ngx-slider';
import { DynamicLocaleId } from '../../../core/util/dynamic-locale-id';
import { DatePipe } from '@angular/common';
import {
    adjustDateMinuteFloor,
    createNewTimeRange,
    isInRange,
    TTimeRange
} from '../../../core/util/time-range';
import { BsModalService } from 'ngx-bootstrap';
import { ModalTimeRangeComponent } from '../modal/time-range/modal-time-range.component';

/**
 * The default minimum range offset in seconds.
 */
const DEFAULT_MIN_RANGE_OFFSET_SECONDS = 900;

/**
 * The default maximum range offset in seconds.
 */
const DEFAULT_MAX_RANGE_OFFSET_SECONDS = 3600 * 24 * 365;

/**
 * The time range component to filter a range between two dates.
 */
@Component({
    selector: 'app-time-range-filter',
    templateUrl: './time-range-filter.component.html',
})
export class TimeRangeFilterComponent implements OnInit, OnChanges {
    /**
     * The time range for minimum and maximum pointer position.
     */
    @Input() private timeRange: TTimeRange = createNewTimeRange();

    /**
     * The time range of the pointer (selected values).
     */
    @Input() private pointerTimeRange?: TTimeRange;

    /**
     * The minimum range offset in seconds.
     */
    @Input() private minRangeOffsetSeconds: number = DEFAULT_MIN_RANGE_OFFSET_SECONDS;

    /**
     * The maximum range offset in seconds.
     */
    @Input() private maxRangeOffsetSeconds: number = DEFAULT_MAX_RANGE_OFFSET_SECONDS;

    /**
     * The output event when the range was changed.
     */
    @Output() public timeRangeChange = new EventEmitter<TTimeRange>();

    /**
     * The output event when the pointer range was changed.
     */
    @Output() public pointerTimeRangeChange = new EventEmitter<TTimeRange>();

    /**
     * The slider options for the ngx-slider component.
     */
    public sliderOptions?: SliderOptions;

    /**
     * The timestamp (in milliseconds) where the range is beginning.
     */
    public pointerBeginTimestamp = 0;

    /**
     * The timestamp (in milliseconds) where the range is ending.
     */
    public pointerEndTimestamp = 0;

    /**
     * The date formatter pipe using the internal date directive.
     */
    private dateFormatter: DatePipe;

    /**
     * Constructor of date range filter component.
     */
    constructor(@Inject(LOCALE_ID) localeId: string | DynamicLocaleId, private modalService: BsModalService) {
        this.dateFormatter = new DatePipe(<string>localeId);
    }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        const [startTime, endTime] = this.timeRange;
        const rangeStartTimestamp = adjustDateMinuteFloor(startTime).getTime();
        const rangeEndTimestamp = adjustDateMinuteFloor(endTime).getTime();
        this.sliderOptions = this.createSliderOptions(rangeStartTimestamp, rangeEndTimestamp);
        this.initializePointerRangePositions(rangeStartTimestamp, rangeEndTimestamp);
    }

    /**
     * Redraw time range on changes.
     */
    public ngOnChanges({ timeRange: timeRangeChanges }: SimpleChanges): void {
        if (timeRangeChanges !== undefined
            && !timeRangeChanges.isFirstChange()
            && timeRangeChanges.currentValue !== undefined
        ) {
            const [startTime, endTime] = timeRangeChanges.currentValue;
            const rangeStartTimestamp = adjustDateMinuteFloor(startTime).getTime();
            const rangeEndTimestamp = adjustDateMinuteFloor(endTime).getTime();
            this.sliderOptions = this.createSliderOptions(rangeStartTimestamp, rangeEndTimestamp);
        }
    }

    /**
     * On range changed by user.
     */
    public onPointerRangeChange(changeContext: ChangeContext) {
        this.pointerTimeRangeChange.emit(this.createTimeRangeFromTimestamps(
            changeContext.value,
            changeContext.highValue
        ));
    }

    /**
     * Open the date picker component.
     */
    public changeTimeRange(): void {
        const timeRangeModal = this.modalService.show(ModalTimeRangeComponent, { class: 'modal-time-range' });
        timeRangeModal.content.timeRangeChange.subscribe((range) => {
            const [startTime, endTime] = range;
            const rangeStartTimestamp = adjustDateMinuteFloor(startTime).getTime();
            const rangeEndTimestamp = adjustDateMinuteFloor(endTime).getTime();
            this.sliderOptions = this.createSliderOptions(rangeStartTimestamp, rangeEndTimestamp);
            this.timeRangeChange.emit(range);
        });
    }

    /**
     * Initialize the position of the pointers.
     */
    private initializePointerRangePositions(rangeBeginTimestamp: number, rangeEndTimestamp: number) {
        this.pointerEndTimestamp = rangeEndTimestamp;
        let pointerBeginTimestamp = rangeEndTimestamp - this.minRangeOffsetSeconds * 1000;
        if (pointerBeginTimestamp < rangeBeginTimestamp) {
            pointerBeginTimestamp = rangeBeginTimestamp;
        }
        this.pointerBeginTimestamp = pointerBeginTimestamp;

        // Use the pointer time range from input if is within the selectable time range.
        if (this.pointerTimeRange !== undefined) {
            const [pointerStartDate, pointerEndDate] = this.pointerTimeRange;
            if (isInRange(pointerStartDate, [rangeBeginTimestamp, rangeEndTimestamp])
                && isInRange(pointerEndDate, [rangeBeginTimestamp, rangeEndTimestamp])
            ) {
                this.pointerBeginTimestamp = adjustDateMinuteFloor(pointerStartDate).getTime();
                this.pointerEndTimestamp = adjustDateMinuteFloor(pointerEndDate).getTime();
            }
        }
    }

    /**
     * Create date range object from timestamps.
     */
    private createTimeRangeFromTimestamps(beginTimestamp, endTimestamp): TTimeRange {
        return [
            new Date(beginTimestamp),
            new Date(endTimestamp)
        ];
    }

    /**
     * Create slider options for ngx-slider.
     */
    private createSliderOptions(rangeBeginTimestamp: number, rangeEndTimestamp: number): SliderOptions {
        return {
            floor: rangeBeginTimestamp,
            ceil: rangeEndTimestamp,
            pushRange: true,
            draggableRange: true,
            translate: this.renderLabels.bind(this),
            ticksTooltip: this.renderFullDate.bind(this),
            getLegend: this.renderTime.bind(this),
            // ticksArray: this.generateTicks(rangeBeginTimestamp, rangeEndTimestamp)
        };
    }

    /**
     * Render labels of ngx-slider component.
     */
    private renderLabels(timestamp: number, label: LabelType): string {
        if ([LabelType.Floor, LabelType.Ceil].includes(label)) {
            return this.renderFullDate(timestamp);
        }
        return this.renderFullDate(timestamp);
    }

    /**
     * Transform a timestamp to a localized time string.
     */
    private renderTime(timestamp: number): string {
        // eslint-disable-next-line max-len
        return `${this.dateFormatter.transform(timestamp, 'shortTime') ?? ''}
        ${this.dateFormatter.transform(timestamp, 'shortDate') ?? ''}`;
    }

    /**
     * Transform a timestamp to a localized full date string.
     */
    private renderFullDate(timestamp: number): string {
        return this.dateFormatter.transform(timestamp, 'short') ?? '';
    }

    /**
     * Generate hourly ticks between two dates.
     */
    private generateTicks(startTimestamp: number, endTimestamp: number): number[] {
        const ticks: number[] = [];
        const amount = 2;
        const space = (endTimestamp - startTimestamp) / (amount - 1);

        for (let i = 0; i < amount; i++) {
            ticks.push(startTimestamp + (space * i));
        }

        return ticks;
    }
}
