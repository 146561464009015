import { IUser } from '../../model/user';

/**
 * The user state type.
 */
export type TUserState = IUser | undefined;

/**
 * The initial user state.
 */
export const initialUserState: TUserState = undefined;
