import { Component, Input } from '@angular/core';
import { ENotificationSeverity, INotification } from '../../../../core/model/notification';

/**
 * Notification icon component.
 */
@Component({
    selector: 'app-notifications-icon',
    templateUrl: './notification-icon.component.html'
})
export class NotificationIconComponent {

    /**
     * The notification record.
     */
    @Input() public notification?: INotification;

    /**
     * Accessor for severity enum in template.
     */
    public severities = ENotificationSeverity;
}
