import { appAdministrationRouteNames, appLogsRouteNames, appReportsRouteNames, appRouteNames } from './app.routes';
import { TMenu } from './core/model/menu';

const MainNav: TMenu = [
    {
        text: 'Main Navigation',
        heading: true,
        roles: 'all',
    },
    {
        text: 'Units',
        link: appRouteNames.UNITS,
        icon: 'icon-organization',
        roles: 'all',
    },
    {
        text: 'Analyze',
        link: appRouteNames.ANALYZE,
        icon: 'icon-graph',
        roles: 'all',
    },
    {
        text: 'Reports',
        link: appRouteNames.REPORTS,
        icon: 'icon-notebook',
        roles: 'all',
        submenu: [
            {
                text: 'Verification',
                link: appReportsRouteNames.VERIFICATION,
                roles: 'all',
            },
            {
                text: 'Battery',
                link: appReportsRouteNames.BATTERY,
                roles: 'all',
            },
        ]
    },
    {
        text: 'Events',
        link: appRouteNames.EVENTS,
        icon: 'icon-event',
        roles: 'all',
    },
    {
        text: 'Snapshots',
        link: appRouteNames.SNAPSHOTS,
        icon: 'icon-wallet',
        roles: 'all',
    },
    {
        text: 'Logs',
        link: appRouteNames.LOGS,
        icon: 'icon-list',
        roles: 'all',
        submenu: [
            {
                text: 'Authentication',
                link: appLogsRouteNames.AUTHENTICATION,
                roles: 'all',
            },
            {
                text: 'Import',
                link: appLogsRouteNames.IMPORT,
                roles: 'all',
            },
        ]
    },
    {
        text: 'Administration',
        link: appRouteNames.ADMINISTRATION,
        icon: 'icon-key',
        roles: 'all',
        submenu: [
            {
                text: 'Units',
                link: appAdministrationRouteNames.UNITS,
                roles: ['AreaManager', 'Administrator', 'SuperAdministrator'],
            },
            {
                text: 'Users & Areas',
                link: appAdministrationRouteNames.USERS,
                roles: ['AreaManager', 'Administrator', 'SuperAdministrator'],
            },
            {
                text: 'Scripts',
                link: appAdministrationRouteNames.SCRIPTS,
                roles: 'SuperAdministrator',
            },
            {
                text: 'User Logs',
                link: appAdministrationRouteNames.LOGS,
                roles: ['Administrator', 'SuperAdministrator'],
            },
            {
                text: 'Notifications',
                link: appAdministrationRouteNames.NOTIFICATIONS,
                roles: 'all',
            },
        ]
    },
    {
        text: 'Upload',
        link: appRouteNames.UPLOAD,
        icon: 'icon-cloud-upload',
        roles: 'all',
    }
];

/**
 * The menu configuration.
 */
export const menu = [
    ...MainNav,
];
