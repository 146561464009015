import { Component, Input } from '@angular/core';
import { IProcess } from '../../../core/model/process';

/**
 * Process component.
 */
@Component({
    selector: 'app-process',
    templateUrl: './process.component.html',
})
export class ProcessComponent {
    /**
     * The process object.
     */
    @Input() public process?: IProcess;
}
