import { Injectable } from '@angular/core';
import {
    AuthResponse,
    DataService as DataApi,
    EventDetailsResponse,
    ParserLogResponse,
    SensorValuesResponse,
    SetupResponse,
    SnapshotResponse
} from '../../../modules/api';
import { IDataService } from './interface-data.service';
import { firstValueFrom } from 'rxjs';
import { IAuthLogRecord } from '../../model/auth-log-record';
import { IImportLogRecord } from '../../model/import-log-record';
import { IEvent } from '../../model/event';
import { IUnitParameter } from '../../model/unit-parameter';
import { ISnapshot } from '../../model/snapshot';

/**
 * The data service to read data from InfluxDB using the backend API.
 */
@Injectable()
export class DataService implements IDataService {

    /**
     * Constructor of the data service.
     */
    constructor(private dataApi: DataApi) {}

    /**
     * Get all data fields of sensors.
     */
    public getAllSensorFields(): Promise<string[]> {
        return firstValueFrom(this.dataApi.apiDataSensorsFieldsGet());
    }

    /**
     * Get all down sampling types of sensors.
     */
    public getAllSensorDownSamplingTypes(): Promise<string[]> {
        return firstValueFrom(this.dataApi.apiDataSensorsDownsamplingtypesGet());
    }

    /**
     * Get data values of sensors.
     */
    public getSensorsValues(
        unitsFilter: string[],
        fieldsFilter: string[],
        start: Date,
        stop: Date,
        downSamplingType?: string
    ): Promise<SensorValuesResponse[]> {
        return firstValueFrom(this.dataApi.apiDataSensorsValuesGet(
            unitsFilter.join(','),
            fieldsFilter.join(','),
            downSamplingType,
            start.toISOString(),
            stop.toISOString()
        ));
    }

    /**
     * Get authentication log entries.
     */
    public getAuthenticationLogs(
        unit: string,
        user: string | undefined,
        start: Date,
        stop: Date
    ): Promise<IAuthLogRecord[]> {
        return firstValueFrom(this.dataApi.apiDataAuthGet(
            unit,
            user,
            start.toISOString(),
            stop.toISOString()
        )).then((response) => this.parseAuthLogsFromResponse(response));
    }

    /**
     * Get import log entries.
     */
    public getImportLogs(unit: string, start: Date, stop: Date, success?: boolean): Promise<IImportLogRecord[]> {
        return firstValueFrom(this.dataApi.apiDataLogsGet(
            unit,
            success,
            start.toISOString(),
            stop.toISOString()
        )).then((response) => this.parseImportLogsFromResponse(response));
    }

    public getSnapshots(unit: string, start: Date, stop: Date): Promise<ISnapshot[]> {
        return firstValueFrom(this.dataApi.apiDataSnapshotsGet(
            unit,
            start.toISOString(),
            stop.toISOString()
        )).then((response) => this.parseSnapshotsFromResponse(response));
    }

    /**
     * Get event entries.
     */
    public getEvents(unit: string, start: Date, stop: Date): Promise<IEvent[]> {
        return firstValueFrom(this.dataApi.apiDataEventsGet(
            start.toISOString(),
            unit,
            stop.toISOString()
        )).then((response) => this.parseEventResponse(response));
    }

    /**
     * Get unit parameter.
     * @throws Error If unit is not specified.
     */
    public getUnitParameter(unit: string, time?: Date): Promise<IUnitParameter[]> {
        if (unit === '') {
            throw new Error('Unit must be specified to load settings');
        }
        return firstValueFrom(this.dataApi.apiDataSetupGet(
            unit,
            time?.toISOString(),
        )).then((response) => this.parseUnitParameter(response));
    }

    /**
     * Parse authentication logs records from response.
     */
    private parseAuthLogsFromResponse(response: AuthResponse[]): IAuthLogRecord[] {
        return response.map((responseItem) => ({
            user: responseItem.user ?? responseItem.type ?? 'Unknown',
            unitSerialNumber: responseItem.ups ?? '',
            time: new Date(responseItem.timestamp ?? 0),
            type: responseItem.type ?? '',
            subType: responseItem.subType ?? '',
        }));
    }

    /**
     * Parse import logs records from response.
     */
    private parseImportLogsFromResponse(response: ParserLogResponse[]): IImportLogRecord[] {
        return response.map((responseItem) => ({
            unitSerialNumber: responseItem.ups ?? '',
            time: new Date(responseItem.timestamp ?? 0),
            file: responseItem.file ?? '',
            success: responseItem.success ?? false,
            processed: responseItem.processed ? new Date(responseItem.processed) : undefined,
            guid: responseItem.guid,
            message: responseItem.message,
            mode: responseItem.mode,
            zipdate: responseItem.zipdate ? new Date(responseItem.zipdate) : undefined
        }));
    }
    private parseSnapshotsFromResponse(response: SnapshotResponse[]): ISnapshot[] {
        return response.map((responseItem) => ({
            unitSerialNumber: responseItem.ups ?? '',
            time: new Date(responseItem.timestamp ?? 0),
            severity: responseItem.severity,
            text: responseItem.text,
            eventIndex: responseItem.eventIndex
        }));
    }

    /**
     * Parse events from response.
     */
    private parseEventResponse(response: EventDetailsResponse[]): IEvent[] {
        return response.map((eventResponse) => ({
            startTime: new Date(eventResponse.startTime ?? 0),
            endTime: eventResponse.endTime ? new Date(eventResponse.endTime) : undefined,
            bid: eventResponse.bid ?? '',
            canNodeId: eventResponse.canNodeId ?? '',
            unitSerialNumber: eventResponse.ups ?? '',
            info: eventResponse.eventIndex ?? '',
            text: eventResponse.eventStartText ?? ''
        }));
    }

    /**
     * Parse unit parameter from response.
     */
    private parseUnitParameter(response: SetupResponse[]): IUnitParameter[] {
        return response.map((responseItem) => ({
            key: `${responseItem.name} (${responseItem.bid})`,
            name: responseItem.name ?? '',
            value: responseItem.value,
            unitType: responseItem.type,
            bid: responseItem.bid,
            canNodeId: responseItem.canNodeId,
            measurement: responseItem.measurement,
            timestamp: responseItem.timestamp,
            ups: responseItem.ups
        }));
    }
}
