import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { createNewTimeRange, createTimeRangeFromTimestamps, TTimeRange } from '../../../../core/util/time-range';
import * as dayjs from 'dayjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';

/**
 * Type definition for time range options.
 */
export type TTimeRangeOptions = Record<string, [dayjs.Dayjs, dayjs.Dayjs]>;

/**
 * The internal time range type.
 */
type TTimeRangeDayjs = { startDate: dayjs.Dayjs | null, endDate: dayjs.Dayjs | null};

/**
 * Create time range options.
 */
const createTimeRangeOptions: () => TTimeRangeOptions = () => ({
    'Last 4 Hours': [dayjs().subtract(4, 'hours'), dayjs()],
    'Last 24 Hours': [dayjs().subtract(24, 'hours'), dayjs()],
    'Last 7 Days': [dayjs().subtract(7, 'day')
        .startOf('day'), dayjs()],
    'Last 30 Days': [dayjs().subtract(30, 'day')
        .startOf('day'), dayjs()],
    'Last 365 Days': [dayjs().subtract(365, 'day')
        .startOf('day'), dayjs()],
    Yesterday: [dayjs().subtract(1, 'day')
        .startOf('day'), dayjs().subtract(1, 'day').endOf('day')],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'This Year': [dayjs().startOf('year'), dayjs().endOf('year')],
});

/**
 * The modal to select a start and end time.
 */
@Component({
    selector: 'app-modal-time-range',
    templateUrl: './modal-time-range.component.html',
})
export class ModalTimeRangeComponent implements OnInit {
    /**
     * The time range.
     */
    @Input() private timeRange: TTimeRange = createNewTimeRange();

    /**
     * The time range output event when range was changed.
     */
    @Output() private timeRangeChange = new EventEmitter<TTimeRange>();

    /**
     * The time range options.
     */
    @Input() public timeRangeOptions: TTimeRangeOptions = createTimeRangeOptions();

    /**
     * The maximum date to select. Default is today.
     */
    @Input() public maxDate: dayjs.Dayjs = dayjs();

    /**
     * The maximum amount of days in a time range.
     */
    @Input() public rangeDaysLimit = 365;

    /**
     * The form control to update.
     */
    @Input() private formControl?: FormControl<TTimeRange>;

    /**
     * The start time. Will be overwritten from timeRange input.
     */
    public startTime: dayjs.Dayjs = dayjs();

    /**
     * The end time. Will be overwritten from timeRange input.
     */
    public endTime: dayjs.Dayjs = dayjs();

    /**
     * The selected time range.
     */
    private selectedTimeRange?: TTimeRange;

    /**
     * The confirmation modal constructor.
     */
    constructor(private bsModalRef: BsModalRef) {}

    /**
     * Set start and end time after component was initialized.
     */
    public ngOnInit(): void {
        this.startTime = dayjs(this.timeRange[0]);
        this.endTime = dayjs(this.timeRange[1]);
        this.bsModalRef.setClass('modal-time-range');
    }

    /**
     * On range was changed by user.
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    public onRangeChange(event: Object) {
        const { startDate, endDate } = <TTimeRangeDayjs>event;
        // eslint-disable-next-line no-null/no-null
        if (startDate !== null && endDate !== null) {
            this.selectedTimeRange = createTimeRangeFromTimestamps(startDate.unix() * 1000, endDate.unix() * 1000);
        }
    }

    /**
     * Select the range.
     */
    public selectRange(): void {
        if (this.selectedTimeRange !== undefined) {
            this.timeRangeChange.emit(this.selectedTimeRange);

            if (this.formControl !== undefined) {
                this.formControl.setValue(this.selectedTimeRange);
            }
        }
        this.bsModalRef.hide();
    }

    /**
     * Select range and close modal when user clicks on a predefined range.
     */
    public onRangeClick(): void {
        setTimeout(this.selectRange.bind(this));
    }
}
