import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

/**
 * Utility type for strictly typed forms.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TControlsOf<T extends Record<string, any>> = {
    [K in keyof T]: FormControl<T[K]>;
};

/**
 * Utility type for strictly typed forms nested.
 */
export type TControlsOfNested<T extends Record<string, any>> = {
    [K in keyof T]: T[K] extends Array<any>
        ? FormControl<T[K]>
        : T[K] extends Record<any, any>
            ? FormGroup<TControlsOfNested<T[K]>>
            : FormControl<T[K]>;
};

/**
 * Utility function to determine if a form control has a specific validator.
 */
export function hasValidator(control: FormControl, validatorName: string): boolean {
    if (control.validator) {
        const validator = control.validator({ } as AbstractControl);
        if (validator && validator[validatorName]) {
            return true;
        }
    }
    return false;
}
