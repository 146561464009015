import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

/**
 * The parameter name to use in translate pipe params
 *  Example: {{ "MyTranslateKey" | translate: { default: "Default Translation" } }}
 */
const DEFAULT_PARAMETER_NAME = 'default';

/**
 * The class to handle missing translation keys.
 */
@Injectable()
export class AppMissingTranslationHelper implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        if (params.interpolateParams) {
            return params.interpolateParams[DEFAULT_PARAMETER_NAME] || params.key;
        }
        return params.key;
    }
}
