import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { protectedResources } from './auth-config';
import { LayoutService } from './core/service/layout.service';

/**
 * The main app component.
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    /**
     * Host binding class for layout fixed.
     */
    @HostBinding('class.layout-fixed') get isFixed() {
        return this.settings.getLayoutSetting('isFixed');
    }

    /**
     * Host binding class for aside collapsed.
     */
    @HostBinding('class.aside-collapsed') get isCollapsed() {
        return this.settings.getLayoutSetting('isCollapsed');
    }

    /**
     * Host binding class for layout boxed.
     */
    @HostBinding('class.layout-boxed') get isBoxed() {
        return this.settings.getLayoutSetting('isBoxed');
    }

    /**
     * Host binding class for layout-fs.
     */
    @HostBinding('class.layout-fs') get useFullLayout() {
        return this.settings.getLayoutSetting('useFullLayout');
    }

    /**
     * Host binding class for hidden footer.
     */
    @HostBinding('class.hidden-footer') get hiddenFooter() {
        return this.settings.getLayoutSetting('hiddenFooter');
    }

    /**
     * Constructor of the main app component.
     */
    constructor(public settings: LayoutService, private auth: MsalService) { }

    /**
     * Get access token.
     */
    @HostListener('document:keydown.alt.shift.a')
    getAccessToken() {
        // eslint-disable-next-line jsdoc/require-jsdoc
        const account = this.auth.instance.getAllAccounts()[0];
        this.auth.acquireTokenSilent(
            {
                account,
                scopes: protectedResources.api.scopes
            })
            .subscribe((result) => {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(result.accessToken);
                    // eslint-disable-next-line no-alert
                    alert('Copied access token to clipboard');
                } else {
                    // eslint-disable-next-line no-alert
                    alert('You browser does not support the clipboard API ☹️');
                }
            });
    }
    /**
     * Host binding class for layout h.
     */
    @HostBinding('class.layout-h') get horizontal() {
        return this.settings.getLayoutSetting('horizontal');
    }

    /**
     * Host binding class for aside float.
     */
    @HostBinding('class.aside-float') get isFloat() {
        return this.settings.getLayoutSetting('isFloat');
    }

    /**
     * Host binding class for offsidebar open.
     */
    @HostBinding('class.offsidebar-open') get offsidebarOpen() {
        return this.settings.getLayoutSetting('offsidebarOpen');
    }

    /**
     * Host binding class for aside toggled.
     */
    @HostBinding('class.aside-toggled') get asideToggled() {
        return this.settings.getLayoutSetting('asideToggled');
    }

    /**
     * Host binding class for aside collapsed text.
     */
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    /**
     * Constructor of app component.
     */

    /**
     * Prevent preloading the page if user clicks on anchor links.
     */
    public ngOnInit(): void {
        // prevent empty links to reload the page
        document.addEventListener('click', (e) => {
            // eslint-disable-next-line jsdoc/require-jsdoc
            const target = e.target as HTMLElement;
            if (target.tagName === 'A' && ['', '#'].indexOf(target.getAttribute('href') || '') > -1) {
                e.preventDefault();
            }
        });
    }
}
