import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEnGBExtra from '@angular/common/locales/extra/en-GB';
import localeDeDE from '@angular/common/locales/de';
import localeDeDEExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * The language config interface.
 */
export interface ILanguageConfig {
    /**
     * Language code.
     */
    code: string;

    /**
     * The text label for select box in frontend.
     */
    text: string;

    /**
     * Angular common language configuration.
     */
    config: unknown;

    /**
     * Angular extra common language configuration.
     */
    configExtra?: unknown;
}

/**
 * The language service.
 */
@Injectable()
export class LanguageService {

    /**
     * The Default language.
     */
    public readonly defaultLanguage = 'en-GB';

    /**
     * Available languages.
     */
    private availableLanguage: ILanguageConfig[] = [
        {
            code: 'en-GB', text: 'English', config: localeEnGB, configExtra: localeEnGBExtra
        },
        {
            code: 'de-DE', text: 'Deutsch', config: localeDeDE, configExtra: localeDeDEExtra
        },
    ];

    /**
     * The observable of language changes.
     */
    public onLanguageChange$: Observable<string>;

    /**
     * Language service constructor.
     */
    constructor(
        private translate: TranslateService,
    ) {
        // Register locale data for system usage (pipes and directives).
        this.availableLanguage.forEach(({ code, config, configExtra }) => {
            registerLocaleData(config, code, configExtra);
        });

        // Set allowed languages and default language.
        this.translate.addLangs(this.availableLanguage.map(({ code }) => code));
        this.translate.setDefaultLang(this.defaultLanguage);

        // Make language change observable public.
        this.onLanguageChange$ = this.translate.onLangChange.pipe(map((event) => event.lang));

        // Set language to use.
        this.useLanguage(this.detectLanguageFromBrowser() || this.defaultLanguage);
    }

    /**
     * Use language.
     */
    public useLanguage(lang?: string) {
        const useLanguage = lang === undefined ? this.translate.getDefaultLang() : lang;
        this.translate.use(useLanguage);
    }

    /**
     * Get current language.
     */
    public getCurrentLanguage(): string {
        return this.translate.currentLang;
    }

    /**
     * Get all available languages.
     */
    public getAvailableLanguages() {
        return this.availableLanguage;
    }

    /**
     * Detect the language from browser.
     */
    public detectLanguageFromBrowser(): string | undefined {
        const { language = '' } = navigator;
        const langCode = language.toLowerCase();
        const availableLangCodes = this.availableLanguage.map(({ code }) => code);

        // Detect language by exact match case insensitive. Example: en-GB
        const exactLangMatch = availableLangCodes
            .filter((currentLangCode) => currentLangCode.toLowerCase() === langCode).shift();
        if (exactLangMatch !== undefined) {
            return exactLangMatch;
        }

        // Detect language by language code (firs two chars) in language code. Example: en
        const langKeyLength = 2;
        const languageKey = langCode.substring(0, langKeyLength);
        return availableLangCodes
            .filter((currentLangCode) => currentLangCode.substring(0, langKeyLength).toLowerCase() === languageKey)
            .shift();
    }
}
