import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ChartComponent, createChartOptions, DEFAULT_CHART_SETTINGS } from '../../../../core/component/chart.component';
import { BsModalService } from 'ngx-bootstrap';
import { ChartService } from '../../../../core/service/chart/chart.service';
import { LoggerService } from '../../../../core/service/logger.service';
import { ProcessService } from '../../../../core/service/process/process.service';
import { EChartType, IChartSettings } from '../../../../core/model/chart-settings';

/**
 * A simple line chart component using the HighCharts library.
 */
@Component({
    selector: 'app-chart-simple-line',
    templateUrl: './chart-simple-line.component.html',
})
export class ChartSimpleLineComponent extends ChartComponent {
    /**
     * Highcharts configuration for simple line chart.
     */
    public chartOptions: Highcharts.Options = createChartOptions(
        this.showDataTable.bind(this),
        {
            xAxis: {
                type: 'datetime'
            },
        },
    );

    /**
     * Default chart settings for simple line chart.
     */
    protected defaultChartSettings: IChartSettings = {
        ...DEFAULT_CHART_SETTINGS,
        type: EChartType.SimpleLine,
        title: 'New simple line chart'
    };

    /**
     * Constructor of the simple line chart component.
     */
    constructor(
        modalService: BsModalService,
        chartService: ChartService,
        logger: LoggerService,
        processService: ProcessService,
    ) {
        super(modalService, chartService, logger, processService);
    }
}
