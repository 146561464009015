/* eslint-disable */
import { OnInit, Directive, Input, ElementRef } from '@angular/core';
declare var $: any;

/**
 * The default height of the scrollable element.
 */
const DEFAULT_HEIGHT = 250;

/**
 * The scrollable directive.
 *
 * TODO: Replace with a native implementation (remove JQuery).
 */
@Directive({
    selector: 'scrollable'
})
export class ScrollableDirective implements OnInit {

    /**
     * The height of the scrollable element.
     */
    @Input() public height: number | string = 0;

    /**
     * Constructor of the scrollable directive.
     */
    constructor(private element: ElementRef) { }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        $(this.element.nativeElement).slimScroll({
            height: (this.height || DEFAULT_HEIGHT)
        });
    }
}
