import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * The modal for rendering the chart data table.
 */
@Component({
    selector: 'app-modal-chart-data-table',
    templateUrl: './chart-data-table.component.html',
})
export class ModalChartDataTableComponent {
    /**
     * The title of the chart.
     */
    @Input() private title = '';

    /**
     * Table header fields.
     */
    @Input() public header: Array<(number|string)> = [];

    /**
     * Data rows from table.
     */
    @Input() public dataRows: Array<Array<(number|string)>> = [];

    /**
     * The confirmation modal constructor.
     */
    constructor(private bsModalRef: BsModalRef) {}

    /**
     * Close the modal.
     */
    public close(): void {
        this.bsModalRef.hide();
    }
}
