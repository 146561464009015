import { Component } from '@angular/core';

/**
 * The footer component.
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
})
export class FooterComponent {
}
