import { Component, Input } from '@angular/core';
import { IProcess } from '../../../core/model/process';

/**
 * Process loader component.
 */
@Component({
    selector: 'app-process-loader',
    templateUrl: './process-loader.component.html',
})
export class ProcessLoaderComponent {
    /**
     * The process object.
     */
    @Input() public process?: IProcess;
}
