import { Component } from '@angular/core';
import { IState } from '../../../core/store/state';
import { Store } from '@ngrx/store';
import { selectUser } from '../../../core/store/user/user.selectors';
import { Observable } from 'rxjs';
import { IUser } from '../../../core/model/user';

/**
 * The user block component.
 */
@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
})
export class UserblockComponent {
    /**
     * User data from store.
     */
    public user$: Observable<IUser | undefined>;

    /**
     * Constructor of user block component.
     */
    constructor(store: Store<IState>) {
        this.user$ = store.select(selectUser);
    }
}
