import * as countryCodes from 'country-codes-list';

/**
 * The interface for a country list item.
 */
export interface ICountryListEntry {
    /**
     * Country alpha-2 code.
     */
    code: string;
    /**
     * Local country name.
     */
    name: string;

    /**
     * The search index.
     */
    searchIndex: string;
}

/**
 * The country list type.
 */
export type TCountryList = ICountryListEntry[];

/**
 * The country list.
 */
export const countryList: TCountryList = countryCodes.all()
    .map((countryInfo) => ({
        code: countryInfo.countryCode,
        name: countryInfo.countryNameEn,
        searchIndex: countryInfo.countryNameLocal.toLowerCase()
            + countryInfo.countryNameEn.toLowerCase()
            + countryInfo.countryCode.toLowerCase()
            + countryInfo.countryCallingCode
    }));
