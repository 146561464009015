import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import * as screenfull from 'screenfull';
import { LayoutService } from '../../../core/service/layout.service';
import { MenuService } from '../../../core/service/menu.service';
import { appAdministrationRouteNames, appRouteNames } from '../../../app.routes';
import { Observable } from 'rxjs';
import { SubscriptionComponent } from '../../../core/component/subscription.component';
import { BsDropdownDirective } from 'ngx-bootstrap';
import { LoggerService } from '../../../core/service/logger.service';
import { NotificationService } from '../../../core/service/notification/notification.service';
import { INotification } from '../../../core/model/notification';
import { ENotificationSeverity } from '../../../core/model/notification';

/**
 * The maximum amount of notifications to show in the header.
 */
const MAX_AMOUNT_NOTIFICATIONS = 3;

/**
 * The header component.
 */
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent extends SubscriptionComponent implements OnInit {

    /**
     * Whether the navigation is collapsed or not.
     */
    public navCollapsed = true;

    /**
     * Notification dropdown child component.
     */
    @ViewChild(BsDropdownDirective) private notificationDropDown?: BsDropdownDirective;

    /**
     * The control to enable fullscreen.
     */
    @ViewChild('fsbutton', { static: true }) fsbutton; // the fullscreen button

    /**
     * Observable of recent notifications.
     */
    public recentNotifications$: Observable<INotification[]>;

    /**
     * Observable of amount of all notifications.
     */
    public allNotificationsCount$: Observable<number>;

    /**
     * Status if notification errors exists.
     */
    public hasErrorNotifications$: Observable<boolean>;

    /**
     * Status if notification warning exists.
     */
    public hasWarningNotifications$: Observable<boolean>;

    /**
     * Whether the notification drop down is enabled or not.
     */
    public notificationDropDownEnabled = false;

    /**
     * Route names use for internal navigation.
     */
    public routeNames = appAdministrationRouteNames;

    /**
     * Constructor of the header module.
     */
    constructor(
        public menu: MenuService,
        public settings: LayoutService,
        private router: Router,
        private authService: MsalService,
        private notificationService: NotificationService,
        private logger: LoggerService,
    ) {
        super();
        this.recentNotifications$ = this.notificationService.getNotifications(
            MAX_AMOUNT_NOTIFICATIONS,
        );
        this.hasErrorNotifications$ = this.notificationService.hasNotificationsOfSeverity(ENotificationSeverity.Error);
        this.hasWarningNotifications$ = this.notificationService.hasNotificationsOfSeverity(
            ENotificationSeverity.Warning
        );
        this.allNotificationsCount$ = this.notificationService.getAllNotificationsCount();

        // Enable disable drop down toggle when notifications exist.
        this.subscriptions.push(
            this.recentNotifications$.subscribe((notifications) => {
                this.notificationDropDownEnabled = notifications.length > 0;
                if (!this.notificationDropDownEnabled && this.notificationDropDown !== undefined) {
                    this.notificationDropDown.hide();
                }
            })
        );

        // Auto close navbar on mobile when route change
        this.subscriptions.push(this.router.events.subscribe(() => {
            if (this.notificationDropDown !== undefined) {
                this.notificationDropDown.hide();
            }
        }));
    }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        // Switch fullscreen icon indicator
        const el = this.fsbutton.nativeElement.firstElementChild;
        screenfull.on('change', () => {
            if (el) {
                el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
            }
        });

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe(() => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });
    }

    /**
     * Logout the user.
     */
    public logout(): void {
        this.authService.logoutRedirect({ postLogoutRedirectUri: `/${appRouteNames.LOGIN}` });
    }

    /**
     * Toggle (show/hide) the offside bar.
     */
    public toggleOffsidebar(): void {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    /**
     * Toggle collapse the sidebar.
     */
    public toggleCollapsedSideabar(): void {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    /**
     * Check if the text is collapsed.
     */
    public isCollapsedText(): boolean {
        return <boolean> this.settings.getLayoutSetting('isCollapsedText');
    }

    /**
     * Toggle full screen mode.
     */
    public toggleFullScreen(): void {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }

    /**
     * Show notification details in a modal.
     */
    public showNotificationDetails(notification: INotification) {
        this.notificationService.showNotificationDetails(notification);
    }

    /**
     * Show notifications in drop or navigate to notification view
     * if no notifications exists.
     */
    public showNotifications() {
        if (this.notificationDropDownEnabled) {
            if (this.notificationDropDown !== undefined) {
                this.notificationDropDown.toggle(true);
            }
        } else {
            this.router.navigateByUrl(appAdministrationRouteNames.NOTIFICATIONS)
                .catch((error) => this.logger.error('Failed to navigation to notifications', error));
        }
    }
}
