import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getNotificationTranslation, NOTIFICATION_MESSAGE_LANG_KEY } from '../../../../core/util/notification';
import { INotification } from '../../../../core/model/notification';

/**
 * Notification message component.
 */
@Component({
    selector: 'app-notifications-message',
    templateUrl: './notification-message.component.html'
})
export class NotificationMessageComponent implements OnChanges {

    /**
     * The notification record.
     */
    @Input() public notification?: INotification;

    /**
     * The notification message to render.
     */
    public message = '';

    /**
     * Constructor of notification message component.
     */
    constructor(private translateService: TranslateService) { }

    /**
     * Evaluate message if inputs changes.
     */
    public ngOnChanges({ notification }: SimpleChanges): void {
        if (notification !== undefined && notification.currentValue !== undefined && this.notification !== undefined) {
            this.message = getNotificationTranslation(
                NOTIFICATION_MESSAGE_LANG_KEY,
                notification.currentValue,
                this.translateService,
                notification.currentValue.message
            );
        }
    }
}
