import { EUserRole, IUser } from '../../model/user';
import { Injectable } from '@angular/core';
import { IGroup } from '../../model/group';
import {
    ApiLogResponse,
    ApiLogService as LogApi,
    GroupResponse,
    GroupService as GroupApi,
    Role,
    UserRequest,
    UserResponse,
    UserService as UserApi
} from '../../../modules/api';
import { firstValueFrom, Observable } from 'rxjs';
import { IUserService } from './interface-user.service';
import { IUserLogRecord } from '../../model/user-log-record';

/**
 * The user service to manage users and groups using the backend api.
 */
@Injectable()
export class UserService implements IUserService {

    /**
     * Constructor of the user service.
     */
    constructor(
        private userApi: UserApi,
        private groupApi: GroupApi,
        private logApi: LogApi
    ) {}

    /**
     * Get all user objects.
     */
    async getAllUsers(): Promise<IUser[]> {
        const responseItems = await firstValueFrom(this.userApi.apiUsersGet());
        return responseItems.map((response) => this.parseUserFromResponse(response));
    }

    /**
     * Get a single user by id.
     */
    async getUserById(id: string): Promise<IUser> {
        const response = await firstValueFrom(this.userApi.apiUsersIdGet(id));
        return this.parseUserFromResponse(response);
    }

    /**
     * Get authenticated user.
     */
    async getAuthenticatedUser(): Promise<IUser> {
        // TODO: Fix return type in swagger config.
        const response = await firstValueFrom(<Observable<UserResponse>><any> this.userApi.apiUsersCurrentUserGet());
        return this.parseUserFromResponse(<UserResponse>response);
    }

    /**
     * Remove a user.
     */
    removeUser(user: IUser): Promise<void> {
        return firstValueFrom(this.userApi.apiUsersIdDelete(user.id));
    }

    /**
     * Create a user.
     */
    async createUser(user: IUser): Promise<string> {
        const userRequest = this.createUserRequest(user);
        const createUserResponse = await firstValueFrom(this.userApi.apiUsersPost(userRequest));
        return this.parseUserFromResponse(createUserResponse).id;
    }

    /**
     * Update a user.
     */
    async updateUser(user: IUser): Promise<void> {
        const userRequest = this.createUserRequest(user);
        await firstValueFrom(this.userApi.apiUsersIdPut(user.id, userRequest));
    }

    /**
     * Get all group objects.
     */
    async getAllGroups(): Promise<IGroup[]> {
        const responseItems = await firstValueFrom(this.groupApi.apiGroupsGet());
        return responseItems.map((response) => this.parseGroupFromResponse(response));
    }

    /**
     * Get a single group by id.
     */
    async getGroupById(id: string): Promise<IGroup> {
        const response = await firstValueFrom(this.groupApi.apiGroupsIdGet(id));
        return this.parseGroupFromResponse(response);
    }

    /**
     * Get user logs for a certain date.
     */
    async getLogs(date: Date): Promise<IUserLogRecord[]> {
        const responseItems = await firstValueFrom(this.logApi.apiLogsGet(date.toISOString()));
        return responseItems.map((response) => this.parseUserLogResponse(response));
    }

    /**
     * Remove a group.
     */
    removeGroup(group: IGroup): Promise<void> {
        return this.groupApi.apiGroupsIdDelete(group.id).toPromise();
    }

    /**
     * Create a group.
     */
    async createGroup(group: IGroup): Promise<string> {
        const createGroupResponse = await firstValueFrom(this.groupApi.apiGroupsPost(group));
        return this.parseGroupFromResponse(createGroupResponse).id;
    }

    /**
     * Update a group.
     */
    async updateGroup(group: IGroup): Promise<void> {
        await this.groupApi.apiGroupsIdPut(group.id, group).toPromise();
    }

    /**
     * Parse user from a user response.
     */
    private parseUserFromResponse(response: UserResponse): IUser {
        // Transform role number to enum.
        let role: EUserRole;
        switch (response.role) {
            case 0: role = EUserRole.SuperAdministrator; break;
            case 1: role = EUserRole.Administrator; break;
            case 2: role = EUserRole.AreaManager; break;
            case 3: role = EUserRole.Technician; break;
            default: role = EUserRole.Technician;
        }

        return {
            role,
            id: response.id || '',
            firstName: response.firstName ?? '',
            lastName: response.lastName ?? '',
            email: response.email ?? '',
            street: response.street ?? '',
            zipCode: response.zipCode ?? '',
            city: response.city ?? '',
            country: response.country ?? '',
            groupIds: response.groupIds ?? [],
            unitIds: response.unitIds ?? [],
        };
    }

    /**
     * Create a user request from an user object.
     */
    private createUserRequest(user: IUser): UserRequest {
        // Transform enum to role number.
        let role: Role;
        switch (user.role) {
            case EUserRole.SuperAdministrator: role = Role.NUMBER_0; break;
            case EUserRole.Administrator: role = Role.NUMBER_1; break;
            case EUserRole.AreaManager: role = Role.NUMBER_2; break;
            case EUserRole.Technician: role = Role.NUMBER_3; break;
            default: role = Role.NUMBER_3;
        }
        return {
            ...user,
            role
        };
    }

    /**
     * Parse group from a group response.
     */
    private parseGroupFromResponse(response: GroupResponse): IGroup {
        return {
            id: response.id || '',
            displayName: response.displayName ?? '',
            email: response.email
        };
    }

    /**
     * Parse user log record from response.
     */
    private parseUserLogResponse(response: ApiLogResponse): IUserLogRecord {
        return {
            time: new Date(response.dateTime ?? 0),
            user: response.user ?? '',
            action: response.action ?? '',
            content: response.content ?? '',
        };
    }
}
