import { Injectable } from '@angular/core';
import { TMenu } from '../model/menu';

/**
 * The menu service.
 */
@Injectable()
export class MenuService {

    /**
     * The menu items.
     */
    private menuItems: TMenu = [];

    /**
     * Add menu.
     */
    public addMenu(menu: TMenu) {
        this.menuItems = [
            ...this.menuItems,
            ...menu
        ];
    }

    /**
     * Get the menu.
     */
    public getMenu(): TMenu {
        return this.menuItems;
    }
}
