/**
 * VerificationServer.Service, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SystemAnalyzeEntryResponse } from './system-analyze-entry-response';


export interface PreviewReportResponse { 
    conclusion?: string | null;
    comment?: string | null;
    unit?: SystemAnalyzeEntryResponse;
    powerBridge1?: SystemAnalyzeEntryResponse;
    powerBridge2?: SystemAnalyzeEntryResponse;
    powerBridge3?: SystemAnalyzeEntryResponse;
    battery1?: SystemAnalyzeEntryResponse;
    battery2?: SystemAnalyzeEntryResponse;
    battery3?: SystemAnalyzeEntryResponse;
    mediumVoltage?: SystemAnalyzeEntryResponse;
    relay?: SystemAnalyzeEntryResponse;
}

