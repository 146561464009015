import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IDataQuery } from '../../../../core/model/data-query';
import { createNewTimeRange } from '../../../../core/util/time-range';
import { TFormMultiSelectOptions } from '../../form/multi-select/form-multi-select.component';
import { Observable } from 'rxjs';
import {
    FormBuilder,
    FormControlStatus,
    FormGroup,
    NonNullableFormBuilder,
    Validators,
    ɵElement as FormElement
} from '@angular/forms';
import { IDataQueryForm, IDataQuerySensorFieldForm } from '../../../../core/model/chart-data-query-form';
import { BsModalService } from 'ngx-bootstrap';
import { ChartService } from '../../../../core/service/chart/chart.service';
import { LoggerService } from '../../../../core/service/logger.service';
import { Store } from '@ngrx/store';
import { IState } from '../../../../core/store/state';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { selectDownSamplingTypes, selectSensorFields } from '../../../../core/store/data/data.selectors';
import { SubscriptionComponent } from '../../../../core/component/subscription.component';
import { ModalTimeRangeComponent } from '../../modal/time-range/modal-time-range.component';
import { loadDownSamplingTypes, loadSensorFields } from '../../../../core/store/data/data.actions';

/**
 * A simple line chart component using the HighCharts library.
 */
@Component({
    selector: 'app-chart-query-form',
    templateUrl: './chart-query-form.component.html',
})
export class ChartQueryFormComponent extends SubscriptionComponent implements OnChanges {

    /**
     * The data query.
     */
    @Input() public query: IDataQuery = { units: [], sensorFields: [], timeRange: createNewTimeRange() };

    /**
     * The data query.
     */
    @Output() public submitQuery = new EventEmitter<IDataQuery>();

    /**
     * The form status of the entire form.
     */
    @Output() public changeFormStatus = new EventEmitter<FormControlStatus>();

    /**
     * All field name options for multi select input.
     */
    public fieldNameOptions$: Observable<TFormMultiSelectOptions>;

    /**
     * Down sampling options as observable.
     */
    public downSamplingOptions$: Observable<TFormMultiSelectOptions>;

    /**
     * The query form.
     */
    public queryForm: FormGroup<{ [K in keyof IDataQueryForm]: FormElement<IDataQueryForm[K], never> }>;

    /**
     * Form builder instance.
     */
    private formBuilder: NonNullableFormBuilder;

    /**
     * Constructor of the chart component.
     */
    constructor(
        private modalService: BsModalService,
        private chartService: ChartService,
        private logger: LoggerService,
        store: Store<IState>,
        { nonNullable: formBuilder }: FormBuilder
    ) {
        super();
        store.dispatch(loadDownSamplingTypes());
        store.dispatch(loadSensorFields());
        this.formBuilder = formBuilder;

        // Create the query form.
        this.queryForm = formBuilder.group<IDataQueryForm>({
            units: formBuilder.control([], Validators.required),
            timeRange: formBuilder.control(createNewTimeRange(), Validators.required),
            sensorFields: formBuilder.array<FormGroup<IDataQuerySensorFieldForm>>([], Validators.required)
        });

        // Submit query changes if value has changed and the data is valid.
        this.subscriptions.push(this.queryForm.statusChanges.pipe(
            filter((status) => status === 'VALID'),
            map(() => this.queryForm.getRawValue()),
            distinctUntilChanged((previousFormData, currentFormData) =>
                JSON.stringify(previousFormData) === JSON.stringify(currentFormData)),
        ).subscribe((query) => {
            this.submitQuery.emit(query);
        }));

        // Output status changes of the form.
        this.subscriptions.push(this.queryForm.statusChanges.subscribe((status) => {
            this.changeFormStatus.emit(status);
        }));

        // Create field name options from sensor fields in store.
        this.fieldNameOptions$ = store.select(selectSensorFields).pipe(
            map((fields) => fields.map((field) => [field, field]))
        );

        // Create download sampling options observable.
        this.downSamplingOptions$ = store.select(selectDownSamplingTypes)
            .pipe(
                map((downSamplingTypes) => downSamplingTypes.map((typeValue) => [typeValue, typeValue]))
            );
    }

    /**
     * Open the date picker component.
     */
    public changeTimeRange(): void {
        this.modalService.show(
            ModalTimeRangeComponent,
            {
                class: 'modal-time-range',
                initialState: {
                    formControl: this.queryForm.controls.timeRange
                }
            }
        );
    }

    /**
     * On changes.
     */
    public ngOnChanges({ query: queryChanges }: SimpleChanges): void {
        if (queryChanges !== undefined && queryChanges.currentValue !== undefined) {
            const { currentValue: query } = queryChanges;
            if (queryChanges.isFirstChange()) {
                this.initializeFormSensorFields(query.sensorFields.length);
            }
            this.queryForm.patchValue(<IDataQuery> query);
        }
    }

    /**
     * Add a new field to the query form.
     */
    public addNewSensorFieldToForm(): void {
        this.queryForm.controls.sensorFields.push(
            this.formBuilder.group<IDataQuerySensorFieldForm>({
                name: this.formBuilder.control('', Validators.required),
                downSamplingTypes: this.formBuilder.control([]),
            })
        );
    }

    /**
     * Remove a field from the query form.
     */
    public removeFieldFromForm(fieldIndex: number): void {
        this.queryForm.controls.sensorFields.removeAt(fieldIndex);
    }

    /**
     * Initialize the form sensor fields.
     */
    private initializeFormSensorFields(amountFields: number) {
        const amountFieldsToAdd = amountFields > 0 ? amountFields : 1;
        [...Array(amountFieldsToAdd).keys()].forEach(() => this.addNewSensorFieldToForm());
    }
}
