/**
 * VerificationServer.Service, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SystemAnalyzeEntryRequest } from './system-analyze-entry-request';


export interface GenerateReportRequest { 
    startDate?: string;
    endDate?: string;
    conclusion?: string | null;
    comment?: string | null;
    unit?: SystemAnalyzeEntryRequest;
    powerBridge1?: SystemAnalyzeEntryRequest;
    powerBridge2?: SystemAnalyzeEntryRequest;
    powerBridge3?: SystemAnalyzeEntryRequest;
    battery1?: SystemAnalyzeEntryRequest;
    battery2?: SystemAnalyzeEntryRequest;
    battery3?: SystemAnalyzeEntryRequest;
    mediumVoltage?: SystemAnalyzeEntryRequest;
    relay?: SystemAnalyzeEntryRequest;
}

