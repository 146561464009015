import { Component } from '@angular/core';
import { ProcessService } from '../../../core/service/process/process.service';
import { Observable } from 'rxjs';

/**
 * Page loader component.
 */
@Component({
    selector: 'app-page-loader',
    templateUrl: './page-loader.component.html',
})
export class PageLoaderComponent {

    /**
     * Has loading processes observable.
     */
    public readonly loading$: Observable<boolean>;

    /**
     * Has locking ui processes observable.
     */
    public readonly lockUi$: Observable<boolean>;

    /**
     * Constructor of page loader component.
     */
    constructor(processService: ProcessService) {
        this.loading$ = processService.hasProcesses();
        this.lockUi$ = processService.hasLockingUiProcesses();
    }
}
