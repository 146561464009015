import { Component } from '@angular/core';
import { LayoutService } from '../../../core/service/layout.service';
import { RedirectRequest } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

/**
 * The user login component.
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent {
    constructor(
        private authService: MsalService,
        public settings: LayoutService,
    ) {
    }

    /**
     * Login the user.
     */
    public login(): void {
        this.authService.loginRedirect({
            redirectStartPage: '/'
        } as RedirectRequest);
    }
}
