import {
    ComponentFactoryResolver, ComponentRef,
    Directive,
    Input, OnChanges, OnDestroy,
    OnInit,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import { IProcess, TProcessId } from '../../../core/model/process';
import { ProcessService } from '../../../core/service/process/process.service';
import { Subscription } from 'rxjs';
import { ProcessLoaderComponent } from '../../component/process-loader/process-loader.component';

/**
 * Process loader directive.
 */
@Directive({
    selector: '[appProcessLoader]'
})
export class ProcessLoaderDirective implements OnInit, OnDestroy, OnChanges {

    /**
     * The process id.
     */
    @Input() private appProcessLoader?: TProcessId;

    /**
     * Process component.
     */
    private processComponent: ComponentRef<ProcessLoaderComponent>;

    /**
     * Process observable subscription.
     */
    private processSubscription?: Subscription;

    /**
     * Constructor of process loader directive.
     */
    constructor(
        private processService: ProcessService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {
        this.processComponent = this.viewContainerRef.createComponent(ProcessLoaderComponent);
    }

    /**
     * On directive initialized.
     */
    public ngOnInit() {
        if (this.appProcessLoader !== undefined) {
            this.registerProcess(this.appProcessLoader);
        }
    }

    /**
     * On component destroyed.
     */
    public ngOnDestroy() {
        this.processComponent.destroy();
        this.unsubscribeFromProcess();
    }

    /**
     * On input process changes.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.appProcessLoader !== undefined) {
            this.registerProcess(changes.appProcessLoader.currentValue);
        }
    }

    /**
     * Register process.
     */
    public registerProcess(processId: string): void {
        this.updateChildComponentProcess(undefined);
        this.unsubscribeFromProcess();
        if (processId === undefined) {
            return;
        }

        const process$ = this.processService.getProcess(processId);
        this.processSubscription = process$.subscribe(
            (process) => this.updateChildComponentProcess(process),
            () => this.updateChildComponentProcess(undefined),
            () => this.updateChildComponentProcess(undefined)
        );
    }

    /**
     * Unsubscribe from process observable.
     */
    public unsubscribeFromProcess(): void {
        if (this.processSubscription !== undefined) {
            this.processSubscription.unsubscribe();
        }
    }

    /**
     * Update child component process.
     */
    public updateChildComponentProcess(process: IProcess | undefined): void {
        this.processComponent.instance.process = process;
    }
}
