import * as Actions from './user.actions';
import { createReducer, on } from '@ngrx/store';
import { initialUserState } from './user.state';

/**
 * The user reducer.
 */
export const userReducer = createReducer(
    initialUserState,
    on(Actions.setUser, (state, { user }) => user)
);
