import { Component } from '@angular/core';

/**
 * A blank layout for the app.
 */
@Component({
    selector: 'app-layout-empty',
    templateUrl: './layout-empty.component.html',
})
export class LayoutEmptyComponent {
}
