import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validator to compare if another field has the equal value.
 */
export const equalTo = (equalControl: AbstractControl): ValidatorFn => {
    let subscribe = false;
    return (control: AbstractControl): ValidationErrors | null => {
        if (!subscribe) {
            subscribe = true;
            equalControl.valueChanges.subscribe(() => {
                control.updateValueAndValidity();
            });
        }

        const controlValue: string = control.value;
        if (equalControl.value === controlValue) {
            // eslint-disable-next-line no-null/no-null
            return null;
        }

        return { equalTo: false };
    };
};
