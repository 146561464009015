/* eslint-disable */
import { OnInit, OnDestroy, Directive, Input, ElementRef } from '@angular/core';
import * as moment from 'moment';

/**
 * The directive to show the current time.
 */
@Directive({
    selector: '[now]'
})
export class NowDirective implements OnInit, OnDestroy {

    /**
     * The format.
     */
    @Input() public format: string = '';

    /**
     * The interval id.
     */
    private intervalId?: NodeJS.Timeout;

    /**
     * Constructor of the now directive.
     */
    constructor(public element: ElementRef) { }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        this.updateTime();
        this.intervalId = setInterval(this.updateTime.bind(this), 1000);
    }

    /**
     * Update the time.
     */
    public updateTime(): void {
        const time = moment().format(this.format);
        this.element.nativeElement.innerHTML = time;
    }

    /**
     * Clear interval if component gets destroyed.
     */
    public ngOnDestroy(): void {
        if (this.intervalId !== undefined) {
            clearInterval(this.intervalId);
        }
    }
}
