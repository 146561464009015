import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as NotificationActions from './notification.actions';
import { combineLatest, of, timer } from 'rxjs';
import { NotificationService } from '../../service/notification/notification.service';
import * as ProcessActions from '../process/process.actions';
import { EProcessId } from '../../model/process';
import { MsalService } from '@azure/msal-angular';

/**
 * The interval in seconds for synchronize notifications.
 */
const SYNC_NOTIFICATION_INTERVAL_MINUTES = 5;

/**
 * Notification effects.
 */
@Injectable()
export class NotificationEffects {

    /**
     * Core notification effects constructor.
     */
    constructor(
        private actions$: Actions,
        private notificationService: NotificationService,
        private authService: MsalService,
    ) {}

    /**
     * Load notifications effect.
     */
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.load),
            switchMap(() => this.notificationService.getUnconfirmedNotifications()),
            map((notifications) => NotificationActions.loaded({ notifications })),
            catchError((error) => of(NotificationActions.loadingFailed({ error })))
        ),
    );

    /**
     * Confirm notifications effect.
     */
    confirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.confirm),
            switchMap(({ notificationId }) => this.notificationService.confirmNotification(notificationId)
                .then(() => NotificationActions.remove({ notificationId }))
                .catch((error) => NotificationActions.confirmationFailed({ notificationId, error }))
            )
        ),
    );

    /**
     * Add confirm process effect for a notification.
     */
    addConfirmProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.confirm),
            map(({ notificationId }) => ProcessActions
                .addProcess({
                    process: {
                        id: `${EProcessId.CoreNotificationConfirm}-${notificationId}`,
                        lockUi: false
                    }
                }))
        ),
    );

    /**
     * Remove confirm process effect for a notification.
     */
    removeConfirmProcess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.confirmationFailed, NotificationActions.remove),
            map(({ notificationId }) => ProcessActions
                .removeProcess({ processId: `${EProcessId.CoreNotificationConfirm}-${notificationId}` }))
        ),
    );

    /**
     * Periodically sync notifications if user is logged in.
     */
    syncNotifications$ = createEffect(() =>
        combineLatest([
            this.authService.handleRedirectObservable().pipe(
                map(() => this.authService.instance.getAllAccounts().length >= 1),
                catchError(() => of(false))
            ),
            timer(0, SYNC_NOTIFICATION_INTERVAL_MINUTES * 60000)
        ]).pipe(
            filter(([loggedIn]) => loggedIn),
            map(() => NotificationActions.load())
        )
    );
}
