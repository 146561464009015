import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * The error modal.
 */
@Component({
    selector: 'app-modal-error',
    templateUrl: './modal-error.component.html',
})
export class ModalErrorComponent implements OnDestroy {
    /**
     * The error title.
     */
    @Input() title?: string;

    /**
     * The error message.
     */
    @Input() message?: string;

    /**
     * The additional error message.
     */
    @Input() additionalMessage?: string;

    /**
     * Dismiss label.
     */
    @Input() dismissLabel = '';

    /**
     * Dismiss output emitter.
     */
    @Output() public readonly dismiss = new EventEmitter<void>();

    /**
     * The error modal constructor.
     */
    constructor(public bsModalRef: BsModalRef) { }

    /**
     * Emit dismiss event when component gets destroyed.
     */
    public ngOnDestroy(): void {
        this.dismiss.emit();
    }
}
