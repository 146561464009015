import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionComponent } from '../../../../core/component/subscription.component';
import { Environment } from '../../../../../environments/environment-interface';
import { equalTo } from '../../../validator/equal-to';

/**
 * The password form component.
 */
@Component({
    selector: 'app-form-password',
    templateUrl: './form-password.component.html',
})
export class FormPasswordComponent extends SubscriptionComponent implements OnInit, DoCheck {

    /**
     * The password form field.
     */
    @Input() public readonly passwordControl: FormControl = new FormControl();

    /**
     * The password field label.
     */
    @Input() public readonly passwordInputLabel: string = '';

    /**
     * The password confirm field label.
     */
    @Input() public readonly passwordConfirmInputLabel: string = '';

    /**
     * The password confirm form field.
     */
    public readonly verifyPasswordControl: FormControl;

    /**
     * Password form component constructor.
     */
    constructor(
        private environment: Environment
    ) {
        super();
        this.verifyPasswordControl = new FormControl(
            '',
            [Validators.required, Validators.minLength(this.environment.passwordMinLength)]
        );
    }

    /**
     * On component initialized
     */
    public ngOnInit(): void {
        // Setup password verification validators.
        this.subscriptions.push(
            combineLatest([this.verifyPasswordControl.valueChanges, this.passwordControl.valueChanges])
                .pipe(
                    debounceTime(this.environment.searchDebounceDelayMs),
                    distinctUntilChanged()
                )
                .subscribe(([verifyPassword, password]) => {
                    const validators: ValidatorFn[] = [Validators.required];
                    if (verifyPassword !== '' && password !== '') {
                        validators.push(equalTo(this.verifyPasswordControl));
                    }
                    this.passwordControl.setValidators(validators);
                    this.passwordControl.updateValueAndValidity();
                })
        );
    }

    /**
     * Use the do check lifecycle hook to mark verify password form control as dirty
     * when the password field was touched (or marked as dirty).
     */
    public ngDoCheck(): void {
        if (this.passwordControl.dirty) {
            this.verifyPasswordControl.markAsDirty();
        }
    }
}
