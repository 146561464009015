import { INotification } from '../../model/notification';

/**
 * The notification state type (only unconfirmed notifications).
 */
export type TNotificationState = INotification[];

/**
 * The notification state.
 */
export const initialNotificationState: TNotificationState = [];
