/**
 * The key used for notification in the JSON localization files.
 */
import { TranslateService } from '@ngx-translate/core';
import { INotification } from '../model/notification';

const NOTIFICATION_LANG_KEY = 'notifications';

/**
 * The key used for notification title in the JSON localization files.
 */
export const NOTIFICATION_TITLE_LANG_KEY = 'TITLE';

/**
 * The key used for notification title in the JSON localization files.
 */
export const NOTIFICATION_MESSAGE_LANG_KEY = 'MESSAGE';

/**
 * Utility function to translate the title or the message of a notification.
 */
export function getNotificationTranslation(
    key: typeof NOTIFICATION_TITLE_LANG_KEY | typeof NOTIFICATION_MESSAGE_LANG_KEY,
    notification: INotification,
    translateService: TranslateService,
    defaultValue = ''
): string {
    const translationKeys = [
        `${notification.code}`,
        `${notification.code}.${notification.severity}`
    ].map((langKey) => [NOTIFICATION_LANG_KEY, langKey, key].join('.'));

    return translationKeys.reduce((title, possibleKey) => {
        const translation = translateService.instant(possibleKey);
        return possibleKey === translation ? title : translation;
    }, defaultValue);
}
