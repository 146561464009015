/**
 * The maximum selectable range in days for snapshots.
 */
import { Component, Input, OnInit, Output } from '@angular/core';
import { SubscriptionComponent } from '../../../core/component/subscription.component';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, EMPTY, merge, Observable, of, switchMap } from 'rxjs';
import { createNewTimeRange, TTimeRange } from '../../../core/util/time-range';
import { ModalService } from '../../../core/service/modal/modal.service';
import { Environment } from '../../../../environments/environment-interface';
import { Store } from '@ngrx/store';
import { IState } from '../../../core/store/state';
import { ActivatedRoute } from '@angular/router';
import { combineLatestWith, debounceTime, map, take } from 'rxjs/operators';
import { IViewFilter } from '../../model/view-filter';

/**
 * The default value of max selectable days for a range.
 */
const DEFAULT_MAX_SELECTABLE_RANGE_DAYS = 365;

/**
 * The default time range offset from now in hours.
 */
const DEFAULT_TIME_RANGE_OFFSET_HOURS = 744;

/**
 * Universal view, which provides a unit/time range filter and custom content
 * using Angular's content projection feature.
 */
@Component({
    selector: 'app-filterable-view',
    templateUrl: './filterable-view.component.html',
})
export class FilterableViewComponent extends SubscriptionComponent implements OnInit {

    /**
     * Filter change output.
     */
    @Output() public filterChange: Observable<IViewFilter>;

    /**
     * The maximum amount of days to select in a range.
     */
    @Input() public maxSelectableRangeDays = DEFAULT_MAX_SELECTABLE_RANGE_DAYS;

    /**
     * Filter change output.
     */
    @Input() public timeRange: TTimeRange = createNewTimeRange(DEFAULT_TIME_RANGE_OFFSET_HOURS);

    /**
     * The form control for unit selection.
     */
    public unitFormControl = new FormControl('', { nonNullable: true });

    /**
     * The selected time range.
     */
    public timeRange$: BehaviorSubject<TTimeRange> = new BehaviorSubject(createNewTimeRange());

    /**
     * Constructor of the search component.
     */
    constructor(
        private modalService: ModalService,
        environment: Environment,
        store: Store<IState>,
        route: ActivatedRoute,
    ) {
        super();
        this.filterChange = merge(
            of(EMPTY).pipe(switchMap(() => of(this.unitFormControl.value))),
            this.unitFormControl.valueChanges
        ).pipe(
            debounceTime(environment.searchDebounceDelayMs),
            combineLatestWith(this.timeRange$.asObservable()),
            map(([unitSerialNumber, timeRange]) => ({
                unitSerialNumber,
                timeRange,
            }))
        );

        // Preselect unit from query params from url.
        this.subscriptions.push(route.queryParams.subscribe(({ unit }) => {
            if (unit !== undefined) {
                this.unitFormControl.setValue(unit);
            }
        }));
    }

    /**
     * Update filter time range if component is initialized.
     */
    public ngOnInit(): void {
        this.timeRange$.next(this.timeRange);
    }

    /**
     * Open the time range modal to select a new range.
     */
    public changeTimeRange(): void {
        this.timeRange$.asObservable().pipe(take(1)).subscribe((timeRange) => {
            this.modalService.selectTimeRange(
                (selectedTimeRange) => this.timeRange$.next(selectedTimeRange),
                timeRange,
                this.maxSelectableRangeDays
            );
        });
    }
}
