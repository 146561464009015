import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INotification } from '../../../../core/model/notification';
import { ENotificationSeverity } from '../../../../core/model/notification';
import { EProcessId } from '../../../../core/model/process';

/**
 * Notification alert component.
 */
@Component({
    selector: 'app-notifications-alert',
    templateUrl: './notification-alert.component.html'
})
export class NotificationAlertComponent {
    /**
     * The notification record.
     */
    @Input() public notification?: INotification;

    /**
     * Whether the notification is confirmable or not.
     */
    @Input() public confirmable = true;

    /**
     * Whether the notification is removable or not.
     */
    @Input() public removable = false;

    /**
     * Whether the notification is clickable or not (only styles).
     */
    @Input() public clickable = false;

    /**
     * Whether the message is visible or not.
     */
    @Input() public showMessage = true;

    /**
     * The notification click emitter.
     */
    @Output() public notificationClick = new EventEmitter<void>();

    /**
     * The notification remove click emitter.
     */
    @Output() public notificationRemoveClick = new EventEmitter<void>();

    /**
     * The notification confirm click emitter.
     */
    @Output() public notificationConfirmClick = new EventEmitter<void>();

    /**
     * The confirm process id prefix.
     */
    public confirmProcessIdPrefix = EProcessId.CoreNotificationConfirm;

    /**
     * Public accessor for severity enum.
     */
    public severity = ENotificationSeverity;
}
