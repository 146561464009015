import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { LayoutService } from '../../../core/service/layout.service';
import { ILanguageConfig, LanguageService } from '../../../core/service/language.service';
import { Router } from '@angular/router';

/**
 * Close sidebar events.
 */
const CLOSE_SIDEBAR_EVENTS = ['click', 'touchstart'];

/**
 * The offside bar (positioned right).
 */
@Component({
    selector: 'app-offsidebar',
    templateUrl: './offsidebar.component.html',
})
export class OffsidebarComponent implements OnInit, OnDestroy {

    /**
     * The selected language.
     */
    public selectedLanguage: string;

    /**
     * Available languages from language service.
     */
    public availableLanguages: ILanguageConfig[];

    /**
     * The click event listener.
     */
    private clickEventListener?: EventListener;

    /**
     * Constructor of the offside bar component.
     */
    constructor(
        public layoutService: LayoutService,
        private languageService: LanguageService,
        private elementRef: ElementRef,
        private router: Router,
    ) {
        this.selectedLanguage = this.languageService.getCurrentLanguage();
        this.availableLanguages = this.languageService.getAvailableLanguages();
    }

    /**
     * Set the active language.
     */
    public setLanguage(language: string): void {
        this.languageService.useLanguage(language);
    }

    /**
     * On component initialized.
     */
    public ngOnInit() {
        this.anyClickClose();
        this.router.events.subscribe(() => {
            this.layoutService.setLayoutSetting('offsidebarOpen', false);
            window.scrollTo(0, 0);
        });
    }

    /**
     * On component destroy.
     */
    public ngOnDestroy() {
        CLOSE_SIDEBAR_EVENTS.forEach((eventName) => {
            if (this.clickEventListener !== undefined) {
                document.removeEventListener(eventName, this.clickEventListener);
            }
        });
    }

    /**
     * Register click handler to close off sidebar.
     */
    private anyClickClose() {
        this.clickEventListener = this.checkCloseOffsidebar.bind(this);
        CLOSE_SIDEBAR_EVENTS.forEach((eventName) => {
            if (this.clickEventListener !== undefined) {
                document.addEventListener(eventName, this.clickEventListener, false);
            }
        });
    }

    /**
     * Handle closing offsidebar.
     */
    private checkCloseOffsidebar(event) {
        const contains = (this.elementRef.nativeElement !== event.target
            && this.elementRef.nativeElement.contains(event.target));
        const isToggleButton = event.target.classList.contains('nav-quick-access__more');
        if (!contains && !isToggleButton) {
            this.layoutService.setLayoutSetting('offsidebarOpen', false);
        }
    }
}
