/* eslint-disable jsdoc/require-jsdoc,max-len */
import { createAction, props } from '@ngrx/store';
import { IUnit } from '../../model/unit';
import { IGroup } from '../../model/group';

// Actions
export const loadSensorFields = createAction('@core/data/load-sensor-fields');
export const loadDownSamplingTypes = createAction('@core/data/load-down-sampling-types');
export const loadUnits = createAction('@core/data/load-units', props<{ force?: boolean }>());
export const loadGroups = createAction('@core/data/load-groups', props<{ force?: boolean }>());

// Events
export const sensorFieldsLoaded = createAction('@core/data/sensor-fields-loaded', props<{ sensorFields: string[] }>());
export const sensorFieldsLoadingFailed = createAction('@core/data/sensor-fields-loading-failed', props<{ error?: any }>());
export const downSamplingTypesLoaded = createAction('@core/data/down-sampling-types-loaded', props<{ downSamplingTypes: string[] }>());
export const downSamplingTypesLoadingFailed = createAction('@core/data/down-sampling-types-loading-failed', props<{ error?: any }>());
export const unitsLoaded = createAction('@core/data/units-loaded', props<{ units: IUnit[] }>());
export const unitsLoadingFailed = createAction('@core/data/units-loading-failed', props<{ error?: any }>());
export const groupsLoaded = createAction('@core/data/groups-loaded', props<{ groups: IGroup[] }>());
export const groupsLoadingFailed = createAction('@core/data/groups-loading-failed', props<{ error?: any }>());
