import { Observable } from 'rxjs';
import { LanguageService } from '../service/language.service';

/**
 * Dynamic locale id provider. Wrap the provider in a class
 * to take advantage of referencing properties.
 */
export class DynamicLocaleId extends String {

    /**
     * Observable of language changes.
     */
    public onChange: Observable<string>;

    /**
     * Constructor of dynamic locale id provider.
     */
    constructor(protected service: LanguageService) {
        super();
        this.onChange = service.onLanguageChange$;
    }

    /**
     * Return locale id wrapped in toString method.
     */
    public toString() {
        return this.service.getCurrentLanguage();
    }
}
