/**
 * VerificationServer.Service, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventDetailsResponse { 
    ups?: string | null;
    startTime?: string;
    endTime?: string | null;
    durationInMs?: number | null;
    eventStartText?: string | null;
    eventStopText?: string | null;
    eventIndex?: string | null;
    bid?: string | null;
    canNodeId?: string | null;
}

