import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * The confirm modal.
 */
@Component({
    selector: 'app-modal-confirm',
    templateUrl: './modal-confirm.component.html',
})
export class ModalConfirmComponent {
    /**
     * The confirmation title.
     */
    @Input() title?: string;

    /**
     * The confirmation message.
     */
    @Input() message?: string;

    /**
     * The accept label.
     */
    @Input() acceptLabel = '';

    /**
     * The decline label.
     */
    @Input() declineLabel = '';

    /**
     * The confirmation result.
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public readonly result: EventEmitter<boolean> = new EventEmitter();

    /**
     * The confirmation modal constructor.
     */
    constructor(private bsModalRef: BsModalRef) { }

    /**
     * Accept and close the modal.
     */
    public accept() {
        this.result.emit(true);
        this.bsModalRef.hide();
    }

    /**
     * Decline and close the modal.
     */
    public decline() {
        this.result.emit(false);
        this.bsModalRef.hide();
    }
}
