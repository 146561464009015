/**
 * Type for a time range.
 */
export type TTimeRange = [Date, Date];

/**
 * Type for a timestamp range.
 */
export type TTimestampRange = [number, number];

/**
 * The default offset in hours used in the `createNewTimeRange` method.
 */
const DEFAULT_TIME_RANGE_HOURS_OFFSET = 3;

/**
 * Check if a date is in a date range.
 */
export function isInRange(date: Date | number, range: TTimeRange | TTimestampRange): boolean {
    const [fromDate, toDate] = range;
    const dateTimestamp = typeof date === 'number' ? date : date.getTime();
    const fromDateTimestamp = typeof fromDate === 'number' ? fromDate : fromDate.getTime();
    const toDateTimestamp = typeof toDate === 'number' ? toDate : toDate.getTime();
    return dateTimestamp >= fromDateTimestamp && dateTimestamp <= toDateTimestamp;
}

/**
 * Adjust (or create) date object to full minute rounded.
 */
export function adjustDateMinuteFloor(date: Date = new Date()): Date {
    const msPerMinute = 60000;
    return new Date(Math.floor(date.getTime() / msPerMinute) * msPerMinute);
}

/**
 * Adjust (or create) date object to full minute rounded up (23:59:59.999).
 */
export function adjustDateMinuteCeil(date: Date = new Date()): Date {
    const msPerMinute = 60000;
    return new Date(Math.ceil(date.getTime() / msPerMinute) * msPerMinute - 1);
}

/**
 * Create a new time range starting x hours in the past.
 */
export function createNewTimeRange(hoursOffset: number = DEFAULT_TIME_RANGE_HOURS_OFFSET): TTimeRange {
    const to = adjustDateMinuteFloor();
    const from = adjustDateMinuteFloor(new Date(Date.now() - hoursOffset * 3600000));
    return [from, to];
}

/**
 * Create date range object from timestamps.
 */
export function createTimeRangeFromTimestamps(beginTimestamp, endTimestamp): TTimeRange {
    return [
        new Date(beginTimestamp),
        new Date(endTimestamp)
    ];
}
