import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { MenuService } from './core/service/menu.service';
import { menu } from './menu';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
    MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE,
    MsalGuardConfiguration, MsalRedirectComponent, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG,
    MsalInterceptorConfiguration
} from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { AuthGuard } from './core/guard/auth.guard';
import { AppMissingTranslationHelper } from './core/util/missing-translation-handler';
import { HttpErrorHandlerInterceptor } from './core/interceptor/http-error-handler-interceptor';
import { BsModalService } from 'ngx-bootstrap';

/**
 * MSAL instance factory.
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * MSAL interceptor config factory.
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    protectedResourceMap.set(
        protectedResources.api.endpoint,
        protectedResources.api.scopes
    );

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

/**
 * MSAL guard config factory.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest,
    };
}

/**
 * Factory to create a new translate loader.
 */
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * The main app module.
 */
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CoreModule,
        SharedModule.forRoot(),
        RouterModule.forRoot(APP_ROUTES),
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        SharedModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: AppMissingTranslationHelper },
        }),
        MsalModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorHandlerInterceptor,
            multi: true,
            deps: [TranslateService, BsModalService]
        },
        MsalService,
        MsalBroadcastService,
        AuthGuard,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {

    /**
     * Constructor of the main app module.
     */
    constructor(menuService: MenuService) {
        menuService.addMenu(menu);
    }
}
