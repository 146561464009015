// tslint:disable:no-null-keyword
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError, combineLatestWith, exhaustMap, filter, map, repeat, switchMap, take
} from 'rxjs/operators';
import { DataService } from '../../service/data/data.service';
import * as DataActions from './data.actions';
import { of } from 'rxjs';
import { LoggerService } from '../../service/logger.service';
import { ProcessService } from '../../service/process/process.service';
import { Store } from '@ngrx/store';
import { IState } from '../state';
import { selectDownSamplingTypes, selectGroups, selectSensorFields, selectUnits } from './data.selectors';
import { UnitService } from '../../service/unit/unit.service';
import { EProcessId } from '../../model/process';
import { UserService } from '../../service/user/user.service';

/**
 * Data effects.
 */
@Injectable()
export class DataEffects {

    /**
     * Data effects constructor.
     */
    constructor(
        private actions$: Actions,
        private store: Store<IState>,
        private dataService: DataService,
        private unitService: UnitService,
        private logger: LoggerService,
        private processService: ProcessService,
        private userService: UserService,
    ) {
    }

    /**
     * Load sensor fields.
     */
    public loadSensorFields$ = createEffect(() => this.actions$.pipe(
        ofType(DataActions.loadSensorFields),
        switchMap(() => this.store.select(selectSensorFields).pipe(take(1))),
        // Abort if fields already loaded.
        filter((fields) => fields.length === 0),
        exhaustMap(() => this.processService.startProcess(
            this.dataService.getAllSensorFields(),
            EProcessId.CoreDataLoadingSensorFields
        )),
        map((sensorFields) => DataActions.sensorFieldsLoaded({ sensorFields })),
        catchError((error) => {
            this.logger.error('Failed to load sensor fields', error);
            return of(DataActions.sensorFieldsLoadingFailed({ error }));
        }),
        repeat()
    ));

    /**
     * Load down sampling types.
     */
    public loadDownSamplingTypes$ = createEffect(() => this.actions$.pipe(
        ofType(DataActions.loadDownSamplingTypes),
        switchMap(() => this.store.select(selectDownSamplingTypes).pipe(take(1))),
        // Abort if fields already loaded.
        filter((downSamplingTypes) => downSamplingTypes.length === 0),
        exhaustMap(() => this.processService.startProcess(
            this.dataService.getAllSensorDownSamplingTypes(),
            EProcessId.CoreDataLoadingDownSamplingTypes
        )),
        map((downSamplingTypes) => DataActions.downSamplingTypesLoaded({ downSamplingTypes })),
        catchError((error) => {
            this.logger.error('Failed to load down sampling types', error);
            return of(DataActions.downSamplingTypesLoadingFailed({ error }));
        }),
        repeat()
    ));

    /**
     * Load units.
     */
    public loadUnits$ = createEffect(() => this.actions$.pipe(
        ofType(DataActions.loadUnits),
        combineLatestWith(this.store.select(selectUnits)),
        // Only continue if units are not loaded or the force flag is set.
        filter(([{ force }, units]) => units.length === 0 || force === true),
        exhaustMap(() => this.processService.startProcess(
            this.unitService.getAllUnits(),
            EProcessId.CoreDataLoadingUnits
        )),
        map((units) => units.sort((a, b) => (a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1))),
        map((units) => DataActions.unitsLoaded({ units })),
        catchError((error) => {
            this.logger.error('Failed to load units', error);
            return of(DataActions.unitsLoadingFailed({ error }));
        }),
        repeat()
    ));

    /**
     * Load groups effect.
     */
    public loadGroups$ = createEffect(() => this.actions$.pipe(
        ofType(DataActions.loadGroups),
        combineLatestWith(this.store.select(selectGroups)),
        // Only continue if units are not loaded or the force flag is set.
        filter(([{ force }, groups]) => groups.length === 0 || force === true),
        exhaustMap(() => this.processService.startProcess(
            this.userService.getAllGroups(),
            EProcessId.CoreDataLoadingGroups
        )),
        map((groups) => DataActions.groupsLoaded({ groups })),
        catchError((error) => {
            this.logger.error('Failed to load groups', error);
            return of(DataActions.groupsLoadingFailed({ error }));
        }),
        repeat()
    ));
}
