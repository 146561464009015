import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriptionComponent } from '../../../../core/component/subscription.component';
import { countryList, TCountryList } from '../../../../core/util/country-list';
import { hasValidator } from '../../../../core/util/form';
import { Environment } from '../../../../../environments/environment-interface';

/**
 * The form country select component.
 */
@Component({
    selector: 'app-form-country-select',
    templateUrl: './form-country-select.component.html',
})
export class FormCountrySelectComponent extends SubscriptionComponent implements OnInit {
    /**
     * The form control instance.
     */
    @Input() public control: FormControl = new FormControl();

    /**
     * The name of the form field.
     */
    @Input() public name = '';

    /**
     * The name of the form field.
     */
    public mandatory = false;

    /**
     * The country search control.
     */
    public countrySearchControl: FormControl = new FormControl('');

    /**
     * The country list favourites observable.
     */
    public countryListFavourites$: Observable<TCountryList>;

    /**
     * The country list without favourites observable.
     */
    public countryList$: Observable<TCountryList>;

    /**
     * On component initialized
     */
    constructor(private environment: Environment) {
        super();

        // Country list observable filtered by user search term.
        const countryList$ = merge(
            of(''),
            this.countrySearchControl.valueChanges.pipe(
                map((searchTerm) => searchTerm.toLowerCase()),
            )
        ).pipe(
            map((searchTerm) => countryList.filter((country) => country.searchIndex.indexOf(searchTerm) !== -1))
        );

        const favorites = this.environment.countryFavorites;
        this.countryListFavourites$ = countryList$.pipe(
            map((countries) =>
                countries.filter(({ code }) => favorites.includes(code))
                    .sort((countryA, countryB) =>
                        favorites.indexOf(countryA.code) - favorites.indexOf(countryB.code))
            )
        );

        this.countryList$ = countryList$.pipe(
            map((countries) => countries.filter(({ code }) => !favorites.includes(code)))
        );
    }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        // Set form input as mandatory if a required validator exists.
        if (hasValidator(this.control, 'required')) {
            this.mandatory = true;
        }
    }
}
