import { Injectable } from '@angular/core';
import { NotificationResponse, NotificationService as NotificationApi } from '../../../modules/api';
import { firstValueFrom, Observable } from 'rxjs';
import { ENotificationCode, ENotificationSeverity, INotification } from '../../model/notification';
import { INotificationService } from './interface-notification.service';
import { Store } from '@ngrx/store';
import { IState } from '../../store/state';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { TOneOrMany } from '../../util/generic-types';
import { selectNotifications } from '../../store/notification/notification.selectors';
import {
    NotificationModalDetailComponent
} from '../../../shared/component/notification/modal-detail/notification-modal-detail.component';

/**
 * The notification service.
 */
@Injectable()
export class NotificationService implements INotificationService {

    /**
     * Notification observable.
     */
    private notifications$: Observable<INotification[]>;

    /**
     * Notification service constructor.
     */
    constructor(
        private notificationApi: NotificationApi,
        private store: Store<IState>,
        private modalService: BsModalService
    ) {
        // Create notification observable with chronologically sorted records.
        this.notifications$ = this.store.select(selectNotifications)
            .pipe(
                map((notifications) => [...notifications].sort((recordA, recordB) =>
                    recordB.creationDate.getTime() - recordA.creationDate.getTime()
                ))
            );
    }

    /**
     * Get notifications optionally filtered by one or many severities
     * and ordered by creation date descending and an optional limit amount of records.
     */
    public getNotifications(
        limit?: number,
        severityFilter?: TOneOrMany<ENotificationSeverity>,
    ): Observable<INotification[]> {
        return this.notifications$.pipe(
            map((notifications) => {
                if (severityFilter === undefined) {
                    return notifications;
                }
                const allowedSeverities = Array.isArray(severityFilter) ? severityFilter : [severityFilter];
                return notifications.filter(({ severity }) => allowedSeverities.includes(severity));
            }),
            map((notifications) => (limit === undefined ? notifications : notifications.slice(0, limit)))
        );
    }

    /**
     * Get all notifications count.
     */
    public getAllNotificationsCount(): Observable<number> {
        return this.notifications$.pipe(map((notifications) => notifications.length));
    }

    /**
     * Check if notifications of a severity type exists.
     */
    public hasNotificationsOfSeverity(severity: ENotificationSeverity): Observable<boolean> {
        return this.notifications$.pipe(
            map((notifications) => {
                const amountNotifications = notifications.filter(
                    (notification) => notification.severity === severity
                ).length;
                return amountNotifications > 0;
            }),
        );
    }

    /**
     * Show notification details and mark it as read.
     */
    public showNotificationDetails(notification: INotification) {
        this.modalService.show(NotificationModalDetailComponent, {
            initialState: {
                notification
            }
        });
    }

    /**
     * Get unconfirmed notifications optionally filtered by unit.
     */
    public async getUnconfirmedNotifications(unitId?: string): Promise<INotification[]> {
        const responseItems = await firstValueFrom(this.notificationApi.apiNotificationsGet(unitId));
        return responseItems.map((response) => this.parseNotificationFromResponse(response));
    }

    /**
     * Confirm a notification.
     */
    public confirmNotification(notificationId: string): Promise<void> {
        return firstValueFrom(this.notificationApi.apiNotificationsIdPut(notificationId));
    }

    /**
     * Get notification history.
     */
    public async getNotificationsHistory(start: Date, stop: Date, unitId?: string): Promise<INotification[]> {
        const responseItems = await firstValueFrom(
            this.notificationApi.apiNotificationsHistoryGet(start.toISOString(), stop.toISOString(), unitId)
        );
        return responseItems.map((response) => this.parseNotificationFromResponse(response));
    }

    /**
     * Parse notification data from response.
     */
    public parseNotificationFromResponse(response: NotificationResponse): INotification {
        return {
            id: response.id ?? '',
            creationDate: new Date(response.notified ?? 0),
            severity: this.emitSeverityFromNotificationResponse(response),
            code: this.emitCodeFromNotificationResponse(response),
            message: response.text ?? '',
            unitSerialNumber: response.serialNumber ?? '',
            acknowledgedEmail: response.acknowledgedEmail ?? '',
            notifiedEmail: response.notifiedEmail ?? '',
            deadlineTime: response.deadline ? new Date(response.deadline) : undefined,
            acknowledgedTime: response.acknowledged ? new Date(response.acknowledged) : undefined,
            dismissedTime: response.dismissed ? new Date(response.dismissed) : undefined,
        };
    }

    /**
     * Get the severity of a notification response. If no severity
     * can be emitted, the default severity is Error.
     */
    private emitSeverityFromNotificationResponse(notificationResponse: NotificationResponse): ENotificationSeverity {
        if (notificationResponse.level === 0) {
            return ENotificationSeverity.Warning;
        }
        return ENotificationSeverity.Error;
    }

    /**
     * Get the severity of a notification response. If no severity
     * can be emitted, the default severity is Error.
     */
    private emitCodeFromNotificationResponse(notificationResponse: NotificationResponse): ENotificationCode {
        const message = (notificationResponse.text ?? '').toLowerCase();
        if (
            ['missing', 'data'].every((word) => message.includes(word))
            && typeof notificationResponse.serialNumber === 'string'
        ) {
            return ENotificationCode.UnitImportFailed;
        }

        return ENotificationCode.Unknown;
    }
}
