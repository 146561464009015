import * as Actions from './process.actions';
import { createReducer, on } from '@ngrx/store';
import { initialProcessState } from './process.state';

/**
 * The process reducer.
 */
export const processReducer = createReducer(
    initialProcessState,
    on(Actions.addProcess, (state, { process: newProcess }) => [
        ...state.filter((process) => process.id !== newProcess.id), newProcess
    ]),
    on(Actions.removeProcess, (state, { processId }) => [
        ...state.filter((process) => process.id !== processId)
    ]),
    on(Actions.removeAllProcesses, () => []),
);
