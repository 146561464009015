import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { INotification } from '../../../../core/model/notification';
import { TranslateService } from '@ngx-translate/core';
import { getNotificationTranslation, NOTIFICATION_TITLE_LANG_KEY } from '../../../../core/util/notification';

/**
 * Notification title component.
 */
@Component({
    selector: 'app-notifications-title',
    templateUrl: './notification-title.component.html'
})
export class NotificationTitleComponent implements OnChanges {
    /**
     * The notification record.
     */
    @Input() public notification?: INotification;

    /**
     * The title to render.
     */
    public title = '';

    /**
     * Constructor of notification title component.
     */
    constructor(private translateService: TranslateService) { }

    /**
     * Evaluate title if inputs changes.
     */
    public ngOnChanges({ notification }: SimpleChanges): void {
        if (notification !== undefined && notification.currentValue !== undefined && this.notification !== undefined) {
            this.title = getNotificationTranslation(
                NOTIFICATION_TITLE_LANG_KEY,
                this.notification,
                this.translateService,
                this.notification.code
            );
        }
    }
}
