import { Component, Input } from '@angular/core';

/**
 * The severity badge component.
 */
@Component({
    selector: 'app-severity-badge',
    templateUrl: './severity-badge.component.html',
})
export class SeverityBadgeComponent {
    /**
     * The severity level.
     */
    @Input() public severity = '';
}
