import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';

import { LayoutService } from './service/layout.service';
import { LanguageService } from './service/language.service';
import { MenuService } from './service/menu.service';

import { throwIfAlreadyLoaded } from './util/module-import-guard';
import { Environment } from '../../environments/environment-interface';
import { environment } from '../../environments/environment';
import { UserService } from './service/user/user.service';
import { UnitService } from './service/unit/unit.service';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_CONFIG } from 'ngx-logger';
import { LoggerService } from './service/logger.service';
import { MsalService } from '@azure/msal-angular';
import {
    ApiModule, Configuration as ApiConfiguration,
    UserService as UserApi,
    GroupService as GroupApi,
    UnitService as UnitApi,
    DataService as DataApi
} from '../modules/api';
import { DataService } from './service/data/data.service';
import { DynamicLocaleId } from './util/dynamic-locale-id';
import { dataReducer } from './store/data/data.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DataEffects } from './store/data/data.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ChartService } from './service/chart/chart.service';
import { ProcessService } from './service/process/process.service';
import { processReducer } from './store/process/process.reducer';
import { metaReducers } from './store/localstorage.meta.reducer';
import { ModalService } from './service/modal/modal.service';
import { NotificationService } from './service/notification/notification.service';
import { notificationReducer } from './store/notification/notification.reducer';
import { NotificationEffects } from './store/notification/notification.effects';
import { userReducer } from './store/user/user.reducer';
import { UserEffects } from './store/user/user.effects';

/**
 * Core module is a module created to define the common services of your Angular app.
 * The services defined under a Core Module are defined as an instance once. This module
 * is imported from the main module as it has singleton services that any element of
 * your Angular app can use. You should import the core module from the root module only.
 */
@NgModule({
    imports: [
        LoggerModule.forRoot(undefined),
        ApiModule.forRoot(() => new ApiConfiguration({})),
        StoreModule.forRoot({
            data: dataReducer,
            notifications: notificationReducer,
            processes: processReducer,
            user: userReducer,
        }, { metaReducers }),
        EffectsModule.forRoot([DataEffects, NotificationEffects, UserEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ],
    providers: [
        UserApi,
        GroupApi,
        UnitApi,
        DataApi,
        LayoutService,
        LanguageService,
        MenuService,
        UserService,
        UnitService,
        DataService,
        ChartService,
        NotificationService,
        ProcessService,
        ModalService,
        LoggerService,
        MsalService,
        { provide: Environment, useValue: environment },
        {
            provide: TOKEN_LOGGER_CONFIG,
            useFactory: (env: Environment) => ({
                level: env.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
                disableConsoleLogging: env.production
            }),
            deps: [Environment]
        },
        {
            provide: ApiConfiguration,
            useFactory: (env: Environment) => new ApiConfiguration({ basePath: env.apiBasePath }),
            deps: [Environment]
        },
        {
            provide: LOCALE_ID,
            deps: [LanguageService],
            useClass: DynamicLocaleId
        },
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class CoreModule {

    /**
     * Constructor of the core module.
     * @param parentModule
     */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
