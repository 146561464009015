import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { IState } from './state';

/**
 * Local storage reducer factory.
 */
function localStorageSyncReducer(reducer: ActionReducer<IState>): ActionReducer<IState> {
    return localStorageSync(
        { keys: ['analyze', 'notifications'], rehydrate: true }
    )(reducer);
}

/**
 * Meta reducers.
 */
export const metaReducers: Array<MetaReducer<IState>> = [localStorageSyncReducer];
