/**
 * The notification severity.
 */
// eslint-disable-next-line no-shadow
export enum ENotificationSeverity {
    Info,
    Warning,
    Error
}

/**
 * The code of the notification.
 */
// eslint-disable-next-line no-shadow
export enum ENotificationCode {
    UnitImportFailed = 'unitImportFailed',
    Unknown = 'unknown',
}

/**
 * The interface for a notification record.
 */
export interface INotification {
    /**
     * The unique identifier for the notification.
     */
    readonly id: string;

    /**
     * The date and time the notification was created.
     */
    creationDate: Date;

    /**
     * The severity.
     */
    severity: ENotificationSeverity;

    /**
     * The optional notification code.
     */
    code: ENotificationCode;

    /**
     * The optional notification message.
     */
    message: string;
    notifiedEmail: string;
    unitSerialNumber: string;
    acknowledgedEmail: string;
    dismissedTime?: Date;
    acknowledgedTime?: Date;
    deadlineTime?: Date;
}
