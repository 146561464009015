import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import * as UserActions from '../user/user.actions';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../../service/user/user.service';

/**
 * User effects.
 */
@Injectable()
export class UserEffects {

    /**
     * Core user effects constructor.
     */
    constructor(
        private actions$: Actions,
        private authService: MsalService,
        private userService: UserService,
    ) {}

    /**
     * Update user if authentication status changes.
     */
    updateUser$ = createEffect(() =>
        this.authService.handleRedirectObservable().pipe(
            map(() => this.authService.instance.getAllAccounts().length >= 1),
            catchError(() => of(false)),
            switchMap((loggedIn) => {
                if (!loggedIn) {
                    return of(UserActions.setUser({ user: undefined }));
                }
                return this.userService.getAuthenticatedUser()
                    .then((user) => UserActions.setUser({ user }));
            }),
        ),
    );
}
