import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ɵElement as FormElement } from '@angular/forms';
import { TControlsOf } from '../../../core/util/form';
import { IGroup } from '../../../core/model/group';
import { TFormMultiSelectOptions } from '../form/multi-select/form-multi-select.component';

/**
 * The form for editing group data.
 */
@Component({
    selector: 'app-group-form',
    templateUrl: './group-form.component.html',
})
export class GroupFormComponent implements OnInit {

    /**
     * The emitter for valid submitted user data.
     */
    @Output() public readonly groupSubmit: EventEmitter<IGroup> = new EventEmitter<IGroup>();

    /**
     * The emitter when user aborts editing the group.
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public readonly abort: EventEmitter<void> = new EventEmitter<void>();

    /**
     * The label for the submit button.
     */
    @Input() public submitLabel = '';

    /**
     * The user data to prefill in the form.
     */
    @Input() public group?: IGroup;

    /**
     * The user form group
     */
    public groupForm: FormGroup<{ [K in keyof TControlsOf<IGroup>]: FormElement<TControlsOf<IGroup>[K], never> }>;

    /**
     * Unit options in the multi select input.
     */
    public unitOptions: TFormMultiSelectOptions = [];

    /**
     * Constructor of edit form component.
     */
    constructor(
        { nonNullable: formBuilder }: FormBuilder,
    ) {
        this.groupForm = formBuilder.group<TControlsOf<IGroup>>({
            displayName: formBuilder.control('', Validators.required),
            id: formBuilder.control(''),
            email: formBuilder.control('')
        });
    }

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        // Prefill the group form if group data exists.
        if (this.group !== undefined) {
            this.groupForm.patchValue(this.group);
        }
    }

    /**
     * Output the valid group data.
     */
    public submitGroup(): void {
        if (this.groupForm.valid) {
            this.groupSubmit.emit(this.groupForm.getRawValue());
        }
    }
}
