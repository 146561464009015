import { OnInit, Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { SubscriptionComponent } from '../../../core/component/subscription.component';
import { EUserRole, TUserRoleKeys, TUserRoleSelector } from '../../../core/model/user';
import { Store } from '@ngrx/store';
import { IState } from '../../../core/store/state';
import { selectUser } from '../../../core/store/user/user.selectors';

/**
 * The directive to show or hide elements depending on the user role.
 */
@Directive({
    selector: '[appUserHasRole]'
})
export class UserHasRoleDirective extends SubscriptionComponent implements OnInit {

    /**
     * The roles which are allowed to view the content.
     */
    @Input() public appUserHasRole?: TUserRoleSelector;

    /**
     * Constructor fo the if roles directive.
     */
    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private store: Store<IState>,
    ) {
        super();
    }

    /**
     * Clear or show container's content if the user role matches.
     */
    public ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(selectUser).subscribe((user) => {
                const role = user === undefined ? undefined : user.role;
                let allowedRoleKeys: TUserRoleKeys[] = [];
                if (this.appUserHasRole !== undefined) {
                    if (this.appUserHasRole === 'all') {
                        allowedRoleKeys = <TUserRoleKeys[]>Object.keys(EUserRole);
                    } else {
                        allowedRoleKeys = Array.isArray(this.appUserHasRole)
                            ? this.appUserHasRole : [this.appUserHasRole];
                    }
                }
                const allowedRoles: EUserRole[] = allowedRoleKeys.map((roleKey) => EUserRole[<keyof EUserRole>roleKey]);

                if (role !== undefined && allowedRoles.includes(role)) {
                    if (this.viewContainerRef.length === 0) {
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                    }
                } else {
                    this.viewContainerRef.clear();
                }
            })
        );
    }
}
