import { IEnvironmentInterface } from './environment-interface';
import { environmentDefault } from './environment-default';

/**
 * Develop environment.
 */
export const environment: IEnvironmentInterface = {
    ...environmentDefault,
    production: false,
};
