import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectProcesses } from '../../store/process/process.selectors';
import { TProcessState } from '../../store/process/process.state';
import { map } from 'rxjs/operators';
import { IProcess, TProcessId } from '../../model/process';
import * as ProcessActions from '../../store/process/process.actions';
import { IState } from '../../store/state';

/**
 * The process service.
 */
@Injectable()
export class ProcessService {

    /**
     * Processes observable.
     */
    private readonly processes$: Observable<TProcessState>;

    /**
     * logger service constructor.
     */
    constructor(
        private store: Store<IState>
    ) {
        this.processes$ = this.store.select(selectProcesses);
    }

    /**
     * Has locking ui processes.
     */
    public hasLockingUiProcesses(): Observable<boolean> {
        return this.processes$.pipe(
            map((processes) => processes.filter((process) => process.lockUi).length > 0)
        );
    }

    /**
     * Get all processes.
     */
    public getProcesses(): Observable<IProcess[]> {
        return this.processes$;
    }

    /**
     * Has running processes.
     */
    public hasProcesses(): Observable<boolean> {
        return this.processes$.pipe(
            map((processes) => processes.length > 0)
        );
    }

    /**
     * Has an active process.
     */
    public hasProcess(processId: TProcessId): Observable<boolean> {
        return this.processes$.pipe(
            map((processes) => processes.filter((process) => process.id === processId).length === 1)
        );
    }

    /**
     * Get a process.
     */
    public getProcess(processId: TProcessId): Observable<IProcess | undefined> {
        return this.processes$.pipe(
            map((processes) => processes.filter((process) => process.id === processId).pop())
        );
    }

    /**
     * Create a process.
     */
    public createProcess(
        processId: TProcessId,
        lockUi = false
    ): void {
        const process: IProcess = {
            id: processId,
            lockUi,
        };
        this.store.dispatch(ProcessActions.addProcess({ process }));
    }

    /**
     * Starts a process and removes it when a promise is fulfilled.
     */
    public startProcess<T>(
        execPromise: Promise<T>,
        processId: TProcessId,
        lockUi = false
    ): Promise<T> {
        this.createProcess(processId, lockUi);
        return execPromise.finally(() => {
            this.removeProcess(processId);
        });
    }

    /**
     * Remove a process by id.
     */
    public removeProcess(processId: TProcessId): void {
        this.store.dispatch(ProcessActions.removeProcess({ processId }));
    }

    /**
     * Remove all processes.
     */
    public removeAllProcess(): void {
        this.store.dispatch(ProcessActions.removeAllProcesses());
    }
}
