import { Injectable } from '@angular/core';
import { IUnit } from '../../model/unit';
import { UnitRequest, UnitResponse, UnitService as UnitApi } from '../../../modules/api';
import { firstValueFrom } from 'rxjs';
import { IUnitService } from './interface-unit.service';

/**
 * The unit service to manage units using the backend API.
 */
@Injectable()
export class UnitService implements IUnitService {

    /**
     * Constructor of the unit service.
     */
    constructor(private unitApi: UnitApi) {}

    /**
     * Get all user objects.
     */
    async getAllUnits(): Promise<IUnit[]> {
        const responseItems = await firstValueFrom(this.unitApi.apiUnitsGet());
        return responseItems.map((response) => this.parseUnitFromResponse(response));
    }

    /**
     * Remove a unit.
     */
    removeUnit(unit: IUnit): Promise<void> {
        return this.unitApi.apiUnitsIdDelete(unit.id).toPromise();
    }

    /**
     * Create a new unit.
     */
    async createUnit(unit: IUnit): Promise<string> {
        const unitRequest = this.createUnitRequest(unit);
        const createUnitResponse = await firstValueFrom(this.unitApi.apiUnitsPost(unitRequest));
        return this.parseUnitFromResponse(createUnitResponse).id;
    }

    /**
     * Get unit data by id.
     */
    async getUnitById(id: string): Promise<IUnit> {
        const response = await firstValueFrom(this.unitApi.apiUnitsIdGet(id));
        return this.parseUnitFromResponse(response);
    }

    /**
     * Update a unit.
     */
    async updateUnit(unit: IUnit): Promise<void> {
        const userRequest = this.createUnitRequest(unit);
        await this.unitApi.apiUnitsIdPut(unit.id, userRequest).toPromise();
    }

    /**
     * Parse unit from a unit response.
     */
    parseUnitFromResponse(response: UnitResponse): IUnit {
        return {
            id: response.id || '',
            groupId: response.groupId || '',
            serialNumber: response.serialNumber || '',
            pillerId: response.pillerId ?? '',
            displayName: response.displayName ?? '',
            street: response.street ?? '',
            city: response.city ?? '',
            zipCode: response.zipCode ?? '',
            country: response.country ?? '',
            notificationRule: response.notificationRule ?? '',
        };
    }

    /**
     * Create a unit request from a unit object.
     */
    createUnitRequest(unit: IUnit): UnitRequest {
        return {
            ...unit,
        };
    }
}
