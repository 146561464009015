import { Route } from '@angular/router';
import { LayoutDefaultComponent } from './shared/component/layout/default/layout-default.component';
import { prefixRouteNames } from './core/util/routes';
import { LayoutEmptyComponent } from './shared/component/layout/empty/layout-empty.component';
import { LoginComponent } from './shared/component/login/login.component';
import { AuthGuard } from './core/guard/auth.guard';
import { EUserRole } from './core/model/user';
import { TOneOrMany } from './core/util/generic-types';
import { NotAuthorizedComponent } from './shared/component/not-authorized/not-authorized.component';

/**
 * The interface for route configuration including role data.
 */
export interface IAuthRoute extends Route {
    data?: { roles?: TOneOrMany<EUserRole> };
    children?: IAuthRoute[];
}

/**
 * Interface for role data in a route configuration.
 */
export type TAuthRouteData = Pick<IAuthRoute, 'data'>

/**
 * Static rout names for the app module.
 */
export const appRouteNames = {
    ANALYZE: 'analyze',
    REPORTS: 'reports',
    ADMINISTRATION: 'administration',
    LOGS: 'logs',
    EVENTS: 'events',
    SNAPSHOTS: 'snapshots',
    UNITS: 'units',
    UPLOAD: 'upload',
    LOGIN: 'login',
    NOT_AUTHORIZED: 'not-authorized',
};

/**
 * Route names for administration part.
 */
export const appAdministrationRouteNames = prefixRouteNames(
    appRouteNames.ADMINISTRATION,
    {
        USERS: 'users',
        UNITS: 'units',
        SCRIPTS: 'scripts',
        LOGS: 'logs',
        NOTIFICATIONS: 'notifications',
    }
);

/**
 * Route names for logs submodules.
 */
export const appLogsRouteNames = prefixRouteNames(
    appRouteNames.LOGS,
    {
        AUTHENTICATION: 'auth',
        IMPORT: 'import'
    }
);

/**
 * i dont know what this is and why i need this, but i just copy pasted it and hope the best
 */
export const appReportsRouteNames = prefixRouteNames(
    appRouteNames.REPORTS,
    {
        VERIFICATION: 'verification',
        BATTERY: 'battery'
    }
);

/**
 * Routing configuration for the app module.
 */
export const APP_ROUTES: IAuthRoute[] = [
    {
        path: '',
        component: LayoutDefaultComponent,
        children: [
            {
                path: '',
                redirectTo: appRouteNames.UNITS,
                pathMatch: 'full'
            },
            {
                path: appRouteNames.ANALYZE,
                loadChildren: () => import('./modules/analyze/analyze.module')
                    .then((m) => m.AnalyzeModule),
                canActivate: [AuthGuard],
            },
            {
                path: appRouteNames.REPORTS,
                loadChildren: () => import('./modules/reports/reports.module')
                    .then((m) => m.ReportsModule),
                canActivate: [AuthGuard],
            },
            {
                path: appRouteNames.EVENTS,
                loadChildren: () => import('./modules/events/events.module')
                    .then((m) => m.EventsModule),
                canActivate: [AuthGuard],
            },
            {
                path: appRouteNames.SNAPSHOTS,
                loadChildren: () => import('./modules/snapshots/snapshots.module')
                    .then((m) => m.SnapshotsModule),
                canActivate: [AuthGuard],
            },
            {
                path: appRouteNames.UNITS,
                loadChildren: () => import('./modules/units/units.module')
                    .then((m) => m.UnitsModule),
                canActivate: [AuthGuard],
            },
            {
                path: appLogsRouteNames.AUTHENTICATION,
                loadChildren: () => import('./modules/logs-auth/logs-auth.module')
                    .then((m) => m.LogsAuthModule),
                canActivate: [AuthGuard],
            },
            {
                path: appLogsRouteNames.IMPORT,
                loadChildren: () => import('./modules/logs-import/logs-import.module')
                    .then((m) => m.LogsImportModule),
                canActivate: [AuthGuard],
            },
            {
                path: appAdministrationRouteNames.USERS,
                loadChildren: () => import('./modules/user-management/user-management.module')
                    .then((m) => m.UserManagementModule),
                canActivate: [AuthGuard],
                data: {
                    roles: [
                        EUserRole.AreaManager,
                        EUserRole.Administrator,
                        EUserRole.SuperAdministrator,
                    ]
                },
            },
            {
                path: appAdministrationRouteNames.UNITS,
                loadChildren: () => import('./modules/unit-management/unit-management.module')
                    .then((m) => m.UnitManagementModule),
                canActivate: [AuthGuard],
                data: {
                    roles: [
                        EUserRole.AreaManager,
                        EUserRole.Administrator,
                        EUserRole.SuperAdministrator,
                    ]
                },
            },
            {
                path: appAdministrationRouteNames.SCRIPTS,
                loadChildren: () => import('./modules/scripts/scripts.module')
                    .then((m) => m.ScriptsModule),
                canActivate: [AuthGuard],
                data: { roles: EUserRole.SuperAdministrator },
            },
            {
                path: appAdministrationRouteNames.LOGS,
                loadChildren: () => import('./modules/logs-user/logs-user.module')
                    .then((m) => m.LogsUserModule),
                canActivate: [AuthGuard],
                data: {
                    roles: [
                        EUserRole.AreaManager,
                        EUserRole.Administrator,
                        EUserRole.SuperAdministrator,
                    ]
                },
            },
            {
                path: appAdministrationRouteNames.NOTIFICATIONS,
                loadChildren: () => import('./modules/notifications/notifications.module')
                    .then((m) => m.NotificationsModule),
                canActivate: [AuthGuard],
            },
            {
                path: appRouteNames.UPLOAD,
                loadChildren: () => import('./modules/upload/upload.module')
                    .then((m) => m.UploadModule),
                canActivate: [AuthGuard],
            },
        ]
    },
    {
        path: '',
        component: LayoutEmptyComponent,
        children: [
            {
                path: appRouteNames.LOGIN,
                component: LoginComponent,
            },
            {
                path: appRouteNames.NOT_AUTHORIZED,
                component: NotAuthorizedComponent,
            },
        ]
    },

    // Not found
    { path: '**', redirectTo: appRouteNames.UNITS }
];
