import { IUnit } from '../../model/unit';
import { IGroup } from '../../model/group';

/**
 * The data state interface.
 */
export interface IDataState {
    /**
     * All available sensor fields.
     */
    sensorFields: string[];

    /**
     * The down sampling types.
     */
    downSamplingTypes: string[];

    /**
     * Units data.
     */
    units: IUnit[];

    /**
     * The groups.
     */
    groups: IGroup[];
}

/**
 * The initial data state.
 */
export const initialDataState: IDataState = {
    sensorFields: [],
    downSamplingTypes: [],
    units: [],
    groups: [],
};
