import * as Actions from './notification.actions';
import { createReducer, on } from '@ngrx/store';
import { initialNotificationState } from './notification.state';

/**
 * The notifications' reducer.
 */
export const notificationReducer = createReducer(
    initialNotificationState,
    on(Actions.loaded, (state, { notifications }) => [...notifications]),
    on(Actions.remove, (state, { notificationId }) => [
        ...state.filter((notification) => notification.id !== notificationId)
    ]),
);
