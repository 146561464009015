/**
 * VerificationServer.Service, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NotificationResponse { 
    id?: string;
    notified?: string;
    text?: string | null;
    level?: number;
    acknowledged?: string | null;
    dismissed?: string | null;
    deadline?: string | null;
    notifiedEmail?: string | null;
    serialNumber?: string | null;
    acknowledgedEmail?: string | null;
}

