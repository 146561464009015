import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * The form errors component.
 */
@Component({
    selector: 'app-form-errors',
    templateUrl: './form-errors.component.html',
})
export class FormErrorsComponent implements OnInit {
    /**
     * The form control instance.
     */
    @Input() public control: FormControl = new FormControl();

    /**
     * Whether a material form wrapper exists or not.
     */
    @Input() public hasMatWrapper = true;

    /**
     * Prefix for each error message.
     */
    @Input() public prefix = '';

    /**
     * Form control errors.
     */
    public errors?: ReadonlyMap<string, ValidationErrors>;

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        this.errors = <ReadonlyMap<string, ValidationErrors>> this.control.errors;
        this.control.statusChanges.subscribe(() => {
            this.errors = <ReadonlyMap<string, ValidationErrors>> this.control.errors;
        });
    }
}
