export * from './alarm.service';
import { AlarmService } from './alarm.service';
export * from './api-log.service';
import { ApiLogService } from './api-log.service';
export * from './data.service';
import { DataService } from './data.service';
export * from './e-mail.service';
import { EMailService } from './e-mail.service';
export * from './group.service';
import { GroupService } from './group.service';
export * from './info.service';
import { InfoService } from './info.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './report.service';
import { ReportService } from './report.service';
export * from './scripts.service';
import { ScriptsService } from './scripts.service';
export * from './unit.service';
import { UnitService } from './unit.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AlarmService, ApiLogService, DataService, EMailService, GroupService, InfoService, NotificationService, ReportService, ScriptsService, UnitService, UserService];
