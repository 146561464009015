import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

/**
 * Human-readable duration component. This component transforms a duration
 * in milliseconds to a human-readable format.
 */
@Component({
    selector: 'app-human-readable-duration',
    templateUrl: './human-readable-duration.component.html',
})
export class HumanReadableDurationComponent implements OnInit, OnChanges {
    /**
     * The milliseconds to transform to human-readable time.
     */
    @Input() public milliseconds = 0;

    /**
     * The prefix of the readable duration.
     */
    @Input() public prefix = '';

    /**
     * The human-readable duration string.
     */
    public readableDuration = '';

    /**
     * On component initialized.
     */
    public ngOnInit(): void {
        this.readableDuration = this.formatDuration(this.milliseconds);
    }

    /**
     * On value has changed.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        let readableDuration = '';
        if (changes.milliseconds !== undefined) {
            readableDuration = this.formatDuration(changes.milliseconds.currentValue);
        }
        this.readableDuration = readableDuration;
    }

    /**
     * Transform milliseconds to a human-readable string.
     */
    private formatDuration(ms): string {
        // Get absolute duration - Transform negative to positive numbers.
        const absMs = ms < 0 ? -ms : ms;

        // If duration is less than one second, output a less than symbol.
        if (absMs !== 0 && absMs < 1000) {
            return '< 1s';
        }

        // Determine all commonly used time units (days, hours, minutes and seconds).
        // The object keys are used as unit information in the formatted string.
        const time = {
            d: Math.floor(absMs / 86400000),
            h: Math.floor(absMs / 3600000) % 24,
            m: Math.floor(absMs / 60000) % 60,
            s: Math.floor(absMs / 1000) % 60
        };

        // Filter time unit values which are empty (value = 0)
        // and format and concatenate them together with a whitespace as delimiter.
        return Object.entries(time)
            .filter((val) => val[1] !== 0)
            .map((val) => `${val[1]}${val[0]}`)
            .join(' ');
    }
}
