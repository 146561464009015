import * as Actions from './data.actions';
import { createReducer, on } from '@ngrx/store';
import { initialDataState } from './data.state';

/**
 * The data reducer.
 */
export const dataReducer = createReducer(
    initialDataState,
    on(Actions.sensorFieldsLoaded, (state, { sensorFields }) => ({ ...state, sensorFields })),
    on(Actions.downSamplingTypesLoaded, (state, { downSamplingTypes }) => ({ ...state, downSamplingTypes })),
    on(Actions.unitsLoaded, (state, { units }) => ({ ...state, units })),
    on(Actions.groupsLoaded, (state, { groups }) => ({
        ...state,
        groups
    })),
);
