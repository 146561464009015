import { Component } from '@angular/core';

/**
 * The default layout component.
 */
@Component({
    selector: 'app-layout-default',
    templateUrl: './layout-default.component.html',
})
export class LayoutDefaultComponent {
}
