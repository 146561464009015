import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    CanLoad,
    UrlTree,
    Router
} from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { concatMap, Observable, of } from 'rxjs';
import {
    MSAL_GUARD_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalService
} from '@azure/msal-angular';
import { IState } from '../store/state';
import { Store } from '@ngrx/store';
import { selectUser } from '../store/user/user.selectors';
import { filter, map } from 'rxjs/operators';
import { EUserRole } from '../model/user';
import { appRouteNames, TAuthRouteData } from '../../app.routes';

/**
 * The authentication guard implements the basic functionalities of
 * the MsalGuard but also take care about the authenticated
 * user's role.
 */
@Injectable()
export class AuthGuard extends MsalGuard implements CanActivate, CanActivateChild, CanLoad {

    /**
     * Constructor of the auth guard.
     */
    constructor(
        @Inject(MSAL_GUARD_CONFIG) msalGuardConfig: MsalGuardConfiguration,
            msalBroadcastService: MsalBroadcastService,
            authService: MsalService,
            location: Location,
        private authRouter: Router,
        private store: Store<IState>,
    ) {
        super(msalGuardConfig, msalBroadcastService, authService, location, authRouter);
    }

    /**
     * @inheritDoc
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        return this.checkUserRole(
            super.canActivate(route, state),
            this.getAllowedRolesForRoute(route)
        );
    }

    /**
     * @inheritDoc
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        return this.checkUserRole(
            super.canActivateChild(route, state),
            this.getAllowedRolesForRoute(route)
        );
    }

    /**
     * @inheritDoc
     */
    canLoad(): Observable<boolean> {
        // The ts-ignore is adopted from the source code of Microsoft's MsalGuard class.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.checkUserRole(
            super.canLoad(),
            []
        );
    }

    /**
     * Check if the user is authenticated (MsalGuard default implementation) and
     * the user's role has permissions to access the route.
     */
    private checkUserRole(
        activateState: Observable<boolean | UrlTree>,
        allowedRoles: EUserRole[]
    ): Observable<boolean | UrlTree> {
        return activateState.pipe(
            concatMap((state) => {
                if (state !== true) {
                    return of(this.authRouter.parseUrl(`/${appRouteNames.NOT_AUTHORIZED}`));
                }

                return this.store.select(selectUser).pipe(
                    filter((user) => user !== undefined),
                    map((user) => {
                        if (user !== undefined && allowedRoles.includes(user.role)) {
                            return true;
                        }
                        return this.authRouter.parseUrl(`/${appRouteNames.NOT_AUTHORIZED}`);
                    })
                );
            })
        );
    }

    /**
     * Get allowed roles for route data.
     *
     * !Attention: If no roles are defined in the route data,
     *  all roles will be accepted.
     */
    private getAllowedRolesForRoute({ data }: TAuthRouteData): EUserRole[] {
        if (data !== undefined && data.roles !== undefined) {
            return Array.isArray(data.roles) ? data.roles : [data.roles];
        }
        return Object.values(EUserRole);
    }
}
