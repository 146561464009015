import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { TTimeRange } from '../../util/time-range';
import { ModalTimeRangeComponent } from '../../../shared/component/modal/time-range/modal-time-range.component';

/**
 * The modal service.
 */
@Injectable()
export class ModalService {

    /**
     * Modal service constructor.
     */
    constructor(private bsModalService:BsModalService) {}

    /**
     * Select a time range.
     */
    public selectTimeRange(
        callback: (selectedTimeRange: TTimeRange) => void,
        initialTimeRange: TTimeRange,
        rangeDaysLimit: number,
    ): void {
        const timeRangeModal = this.bsModalService.show(ModalTimeRangeComponent, {
            initialState: {
                rangeDaysLimit,
                timeRange: initialTimeRange,
            },
            class: 'modal-time-range'
        });
        timeRangeModal.content.timeRangeChange.subscribe((selectedTimeRange) => {
            callback(selectedTimeRange);
        });
    }
}
