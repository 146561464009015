/**
 * Utility function to prefix route names.
 */
export function prefixRouteNames<T extends Record<string, string>>(prefix: string, routeNames: T, absolute = false): T {
    return Object.assign({ }, ...Object.entries(routeNames)
        .map(([key, value]) => {
            const seperator = prefix === '' ? '' : '/';
            const absolutePrefix = absolute ? '/' : '';
            return { [key]: absolutePrefix + prefix + seperator + value };
        }));
}
