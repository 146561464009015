import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { ENotificationSeverity, INotification } from '../../../../core/model/notification';
import * as NotificationActions from '../../../../core/store/notification/notification.actions';

/**
 * The notification modal detail component.
 */
@Component({
    selector: 'app-notification-modal-detail',
    templateUrl: './notification-modal-detail.component.html',
})
export class NotificationModalDetailComponent {
    /**
     * The notification to show details for.
     */
    @Input() notification?: INotification;

    /**
     * Accessor for severity enum in template.
     */
    public severities = ENotificationSeverity;

    /**
     * The notification modal detail constructor.
     */
    constructor(
        private bsModalRef: BsModalRef,
        private store: Store,
    ) {}

    /**
     * Close the modal.
     */
    public close() {
        this.bsModalRef.hide();
    }

    /**
     * Confirm notification and close modal.
     */
    public confirmAndClose() {
        if (this.notification !== undefined) {
            this.store.dispatch(NotificationActions.confirm({ notificationId: this.notification.id }));
        }
        this.bsModalRef.hide();
    }
}
